import React, { createContext } from 'react';
import { useImmerReducer } from 'use-immer';
import ls from 'local-storage';
import { useQuery } from '@apollo/react-hooks';
import { SHOW_VIEWER } from '../graphql/queries';
import { AccountProvider } from './Account';
import { PAGES, URL } from '../_config';
import { ViewerReducer, VIEWER } from './reducers';

export const ViewerContext = createContext(VIEWER.INITIAL_STATE);

export const ViewerProvider = ({ children }) => {
    const [state, dispatch] = useImmerReducer(ViewerReducer, VIEWER.INITIAL_STATE);

    let { loading } = useQuery(SHOW_VIEWER, {
        context: { passport: true },
        onCompleted: (data) => dispatch({ type: VIEWER.GET, payload: { viewer: data.viewer } }),
    });

    const setDirectoriesView = (view) => {
        dispatch({ type: VIEWER.DIRECTORIES_VIEW, payload: { view } });
    };

    const logout = () => {
        ls.remove('user');
        window.location.href = `${URL.DOYO}${PAGES.EXIT}/${URL.FACE}`;
    };

    const setLanguage = (language) => {};

    return (
        <ViewerContext.Provider
            value={{
                ...state,
                loading,
                logout,
                setDirectoriesView,
                setLanguage,
            }}
        >
            <AccountProvider>{children}</AccountProvider>
        </ViewerContext.Provider>
    );
};

export const ViewerConsumer = ViewerContext.Consumer;
export default ViewerContext;
