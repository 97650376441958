// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { Checkbox, message, Tooltip } from 'antd';
import {
    Button,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    UncontrolledDropdown,
} from 'reactstrap';
import { PAGES, URL } from '../../_config';
import { downloadBlob, fileBlackList, findInTree, getSelectionText } from '../../_helpers';
import { CustomModal, Icon, RenameTag, RenameTagging } from '../../components';
import { useAccounts, useTaggings, useTags } from '../../hooks';

const Sharing = ({ tagging }) => {
    const { t } = useTranslation();
    const { createSharing, deleteSharing } = useTaggings();
    const [raw, setRaw] = useState(false);
    const {
        id: taggingId,
        file = {},
        sharings: { edges: sharingEdges = [] },
    } = tagging;
    const { node: sharing = {} } = sharingEdges[0] || {};
    const { extension = '' } = file;
    return (
        <>
            {(!sharing.id && (
                <React.Fragment>
                    <p>{t('directories.sharing.request_text')}</p>
                    <Button
                        onClick={() =>
                            createSharing({
                                variables: { taggingId },
                            })
                        }
                    >
                        {t('directories.sharing.request_link')}
                    </Button>
                </React.Fragment>
            )) || (
                <React.Fragment>
                    <p>{t('directories.sharing.delete_text')}</p>
                    <div className="mb-2">
                        <Checkbox
                            id="RawSharing"
                            aria-describedby="direct download"
                            onChange={() => setRaw((raw) => !raw)}
                            checked={raw}
                        >
                            {t('directories.raw_file')}
                        </Checkbox>
                    </div>
                    <Input
                        id="SharingLink"
                        readOnly={true}
                        value={
                            `${URL.FACE}${PAGES.SHARING}/${sharing.token}${
                                raw ? `.${extension}` : ''
                            }` || ''
                        }
                    />
                    <div className="d-flex mt-3">
                        <Button
                            className="mr-2"
                            color="danger"
                            onClick={() => deleteSharing({ variables: { id: sharing.id } })}
                        >
                            {t('directories.sharing.delete_link')}
                        </Button>
                        <Button
                            onClick={(event) => {
                                const target = event.currentTarget;
                                const input = document.getElementById('SharingLink');
                                input.select();
                                document.execCommand('copy');
                                target.classList.add('copied');
                            }}
                        >
                            <Icon type="check" className="check" />
                            {t('common.copy')}
                        </Button>
                    </div>
                </React.Fragment>
            )}
        </>
    );
};

const ShareButton = () => {
    const { t } = useTranslation();
    const {
        checkedTaggings,
        current: currentTagging,
        taggingToShow,
        uncheckAllTaggings,
    } = useTaggings();
    const [isSharingOpened, setIsSharingOpened] = useState(false);
    let tagging =
        (checkedTaggings.length && checkedTaggings[0]) ||
        (currentTagging.id && currentTagging) ||
        (taggingToShow.id && taggingToShow);

    return (
        <CustomModal
            button={
                <Tooltip title={t('common.share')}>
                    <Icon type="share" className="square" />
                </Tooltip>
            }
            buttonParameters={{
                color: 'link',
                className: 'badge-pill p-0 border-0',
                style: {
                    width: '40px',
                },
            }}
            isOpen={isSharingOpened}
            title={t('files.share_file', {
                count: checkedTaggings.length,
            })}
            className="share-file"
            toggle={() => setIsSharingOpened((isOpen) => !isOpen)}
            onClosed={() => uncheckAllTaggings()}
        >
            <Sharing tagging={tagging} />
        </CustomModal>
    );
};

export const Actions = ({ download = false }) => {
    const { t } = useTranslation();
    const [, update] = useState();
    const { current: currentAccount = {} } = useAccounts();
    const {
        checkedTaggings = [],
        copyTaggings,
        createTagging,
        current: currentTagging = {},
        cutTaggings,
        deleteTagging,
        downloadTaggings,
        getTaggingsFromTag,
        pasteTaggings,
        replaceTaggingFile,
        taggings = { edges: [] },
        taggingToShow = {},
        uncheckAllTaggings,
    } = useTaggings();
    const {
        checkedTags = [],
        copyTags,
        current: currentTag = {},
        cutTags,
        deleteTag,
        hierarchical = [],
        pasteTags,
        tags = { edges: [] },
        uncheckAllTags = () => {},
    } = useTags();
    const someChecked = !!(
        checkedTaggings.length ||
        checkedTags.length ||
        currentTagging.id ||
        taggingToShow.id
    );
    let clipboard = {
        taggings: taggings.edges.filter(({ copied, cuted }) => copied || cuted),
        tags: tags.edges.filter(({ copied, cuted }) => copied || cuted),
    };
    clipboard = ((clipboard.taggings.length || clipboard.tags.length) && clipboard) || null;
    const { file = {} } = currentTagging;
    const { mime_type: mimeType = '' } = file;
    const currentTaggingIsText = mimeType.replace(/\/.*$/, '') === 'text';

    useEffect(() => {
        document.addEventListener('keydown', handleShortcuts, false);
        return () => {
            document.removeEventListener('keydown', handleShortcuts, false);
        };
    }, []);

    const handleCopy = useCallback(() => {
        copyTaggings(checkedTaggings);
        copyTags(checkedTags);

        const texts = [
            t('directories.tags', { count: checkedTaggings.length }),
            t('directories.taggings', { count: checkedTags.length }),
        ].filter((text) => text[0] !== '0');
        const description = `${t('common.copied')}: ${texts.join(` ${t('common.and')} `)}`;
        message.success(description);
    }, [checkedTaggings, checkedTags]);

    const handleCut = useCallback(() => {
        cutTaggings(checkedTaggings);
        cutTags(checkedTags);

        const texts = [
            t('directories.tags', { count: checkedTags.length }),
            t('directories.taggings', { count: checkedTaggings.length }),
        ].filter((text) => text[0] !== '0');
        const description = `${t('common.cuted')}: ${texts.join(` ${t('common.and')} `)}`;
        message.success(description);
    }, [checkedTaggings, checkedTags]);

    const handlePaste = () => {
        const pastedTaggings = taggings.edges.filter(({ copied, cuted }) => copied || cuted);
        const pastedTags = tags.edges.filter(({ copied, cuted }) => copied || cuted);
        pasteTaggings(pastedTaggings, { tag: currentTag });
        pasteTags(pastedTags, { tag: currentTag, createTagging });
    };

    const handleShortcuts = (event) => {
        const { ctrlKey, key, metaKey } = event;

        if (getSelectionText() || (!ctrlKey && !metaKey) || ['Meta', 'Control'].includes(key)) {
            return;
        }

        switch (key.toLowerCase()) {
            case 'c': {
                event.preventDefault();
                handleCopy();
                break;
            }
            case 's': {
                event.preventDefault();
                document.getElementById('DownloadButton').click();
                break;
            }
            case 'v': {
                event.preventDefault();
                handlePaste();
                break;
            }
            case 'x': {
                event.preventDefault();
                handleCut();
                break;
            }
            default: {
            }
        }
    };

    const deleteTagsAndChilds = (tagsToDelete) => {
        tagsToDelete.forEach(async (tag) => {
            const { name } = tag;
            const dir = findInTree(tag, hierarchical) || {};
            let { children = [] } = dir;
            children = children
                .map((dir) => (tags.edges.find(({ node: { id } }) => id === dir.id) || {}).node)
                .filter((node) => node);
            deleteTagsAndChilds(children);
            const {
                data: {
                    createTag: { taggings },
                },
            } = await getTaggingsFromTag({ variables: { accountId: currentAccount.id, name } });
            taggings.edges.forEach(({ node: { id } }) => deleteTagging({ variables: { id } }));
            deleteTag({
                variables: {
                    id: tag.id,
                },
            });
        });
    };

    const handleDelete = useCallback(() => {
        deleteTagsAndChilds(checkedTags);
        checkedTaggings.forEach((tagging) => {
            deleteTagging({
                variables: {
                    id: tagging.id,
                },
            });
        });
    }, [checkedTaggings, checkedTags]);

    const handleDownload = useCallback(async () => {
        const tagsToFetch = tags.edges.filter(({ node: tag }) =>
            checkedTags.some(({ node: t }) => tag.to.includes(t.to))
        );

        const tagsWithTaggings = (
            await Promise.all(
                tagsToFetch.map(({ node: { name } }) =>
                    getTaggingsFromTag({ variables: { accountId: currentAccount.id, name } })
                )
            )
        ).map(({ data: { createTag } }) => createTag);
        const taggingsFromCheckedTags = tagsWithTaggings.reduce(
            (arr, { taggings }) => [...arr, ...(taggings.edges || [])],
            []
        );

        let { title } = currentTag;
        title = title === '/' ? 'kustodio' : title;

        const taggingsToDownload = currentTagging.id
            ? [currentTagging]
            : [...taggingsFromCheckedTags, ...checkedTaggings];

        downloadTaggings(taggingsToDownload)
            .then((downloadedFiles) => {
                if (currentTagging.id || (checkedTaggings.length === 1 && !checkedTags.length)) {
                    const { file, name } = currentTagging.id ? currentTagging : checkedTaggings[0];
                    const { blob } = downloadedFiles.find((f) => f.id === file.id);
                    downloadBlob(blob, name);
                    uncheckAllTaggings();
                    return;
                }

                const zip = new JSZip();

                const appendFolder = (directory, { parent }) => {
                    const { children = [], title } = directory;
                    const folder = parent.folder(title);
                    const tag = tagsWithTaggings.find((tag) => tag.id === directory.id);
                    const { taggings = { edges: [] } } = tag;
                    taggings.edges.forEach(({ node: { file, name } }) => {
                        const { blob } = downloadedFiles.find(({ id }) => id === file.id);
                        folder.file(name, blob);
                    });
                    children.forEach((dir) => appendFolder(dir, { parent: folder }));
                };

                checkedTags.forEach((tag) =>
                    appendFolder(findInTree(tag, hierarchical), { parent: zip })
                );

                checkedTaggings.forEach(({ file, name }) => {
                    const { blob } = downloadedFiles.find((f) => f.id === file.id);
                    zip.file(name, blob);
                });
                return zip.generateAsync({ type: 'blob' });
            })
            .then((zip) => {
                zip && downloadBlob(zip, `${title}.zip`);
                uncheckAllTaggings();
                uncheckAllTags();
            });
    }, [currentTagging]);

    const handleSave = useCallback(() => {
        const content = window.$('.preview-body > textarea')[0].value;
        const file = new Blob([content]);
        replaceTaggingFile({
            variables: { file, taggingId: currentTagging.id },
        });
    }, [currentTagging]);

    const SaveButton = useCallback(() => {
        return (
            <Button
                color="link"
                className="badge-pill p-0 border-0"
                style={{ width: '40px' }}
                data-cy="save"
                onClick={handleSave}
            >
                <Tooltip title={t('common.save')}>
                    <Icon type="content-save" className="square" />
                </Tooltip>
            </Button>
        );
    }, [currentTagging]);

    const ChangeFileButton = () => {
        let tagging = currentTagging.id ? currentTagging : checkedTaggings[0];
        let { file = {} } = tagging;
        let { mimeType } = file;
        return (
            <>
                <Button
                    color="link"
                    className="badge-pill p-0 border-0"
                    style={{ width: '40px' }}
                    data-cy="download"
                    onClick={(event) => {
                        event.currentTarget.nextSibling.click();
                    }}
                >
                    <Tooltip title={t('common.change_file')}>
                        <Icon type="file-replace" className="square" />
                    </Tooltip>
                </Button>
                <Input
                    className="d-none"
                    id="FileChangeInput"
                    onChange={async (event) => {
                        const changedFiles = Object.values(event.currentTarget.files).filter(
                            ({ name }) => !fileBlackList.includes(name)
                        );
                        const file = changedFiles[0];
                        await replaceTaggingFile({
                            variables: { file, taggingId: tagging.id },
                        });
                        update(Date.now());
                    }}
                    type="file"
                />
            </>
        );
    };

    const DownloadButton = () => {
        return (
            <Button
                id="DownloadButton"
                color="link"
                className="badge-pill p-0 border-0"
                style={{ width: '40px' }}
                data-cy="download"
                onClick={handleDownload}
            >
                <Tooltip title={t('common.download')}>
                    <Icon type="download" className="square" />
                </Tooltip>
            </Button>
        );
    };

    const CopyButton = () => {
        return (
            <DropdownItem
                id="CopyButton"
                className="badge-pill p-0 border-0"
                style={{ width: '40px' }}
                data-cy="copy"
                onClick={handleCopy}
            >
                <Tooltip title={t('common.copy')}>
                    <Icon type="content-copy" className="square" />
                </Tooltip>
                <span>{t('common.copy')}</span>
            </DropdownItem>
        );
    };

    const CutButton = () => {
        return (
            <DropdownItem
                id="CutButton"
                className="badge-pill p-0 border-0"
                style={{ width: '40px' }}
                data-cy="cut"
                onClick={handleCut}
            >
                <Tooltip title={t('common.cut')}>
                    <Icon type="content-cut" className="square" />
                </Tooltip>
                <span>{t('common.cut')}</span>
            </DropdownItem>
        );
    };

    const PasteButton = () => {
        return (
            <DropdownItem
                id="PasteButton"
                color="link"
                className="badge-pill p-0 border-0"
                disabled={
                    !!taggings.edges.filter(
                        ({ cuted, node }) => cuted && node.tag.id === currentTag.id
                    ).length ||
                    !!tags.edges.filter(
                        ({ cuted, node }) =>
                            cuted && node.name === `${currentTag.name}/${node.title}`
                    ).length
                }
                style={{ width: '40px' }}
                data-cy="paste"
                onClick={handlePaste}
            >
                <Tooltip title={t('common.paste')}>
                    <Icon type="content-paste" className="square" />
                </Tooltip>
                <span>{t('common.paste')}</span>
            </DropdownItem>
        );
    };

    const RenameTagButton = ({ tag }) => {
        return (
            <CustomModal
                button={
                    <>
                        <Tooltip title={t('common.rename')}>
                            <Icon type="pencil" className="square" />
                        </Tooltip>
                        <span>{t('common.rename')}</span>
                    </>
                }
                buttonParameters={{
                    color: 'link',
                    className: 'dropdown-item badge-pill p-0 border-0',
                    style: {
                        width: '40px',
                    },
                }}
                onOpened={() => document.getElementById('InputRenameTag').focus()}
                onClosed={() => uncheckAllTags()}
                title={t('directories.rename_folder')}
            >
                <RenameTag tag={tag} />
            </CustomModal>
        );
    };

    const RenameTaggingButton = ({ tagging }) => {
        return (
            <CustomModal
                button={
                    <>
                        <Tooltip title={t('common.rename')}>
                            <Icon type="pencil" className="square" />
                        </Tooltip>
                        <span>{t('common.rename')}</span>
                    </>
                }
                buttonParameters={{
                    color: 'link',
                    className: 'dropdown-item badge-pill p-0 border-0',
                    style: {
                        width: '40px',
                    },
                }}
                onOpened={() => document.getElementById('InputRenameTagging').focus()}
                onClosed={() => uncheckAllTaggings()}
                title={t('files.rename_file')}
            >
                <RenameTagging tagging={tagging} />
            </CustomModal>
        );
    };

    const DeleteButton = () => {
        return (
            <DropdownItem
                id="DeleteButton"
                color="link"
                className="badge-pill p-0 border-0"
                style={{ width: '40px' }}
                data-cy="delete"
                onClick={handleDelete}
            >
                <Tooltip title={t('common.delete')}>
                    <Icon type="delete" className="square" />
                </Tooltip>
                <span>{t('common.delete')}</span>
            </DropdownItem>
        );
    };

    return (
        <React.Fragment>
            <UncontrolledDropdown id="ActionsHeader">
                <React.Fragment>
                    {currentTaggingIsText && <SaveButton />}
                    {!download &&
                        (checkedTaggings.length === 1 || currentTagging.id) &&
                        !checkedTags.length && (
                            <>
                                <ChangeFileButton />
                                <ShareButton />
                            </>
                        )}
                    {(download || someChecked) && <DownloadButton />}
                </React.Fragment>
                {currentAccount.id && (
                    <DropdownToggle nav className="p-2 icon border-0 rounded-circle">
                        <Icon type="dots-vertical" />
                    </DropdownToggle>
                )}

                <DropdownMenu right>
                    {someChecked && (
                        <React.Fragment>
                            {<CopyButton />}
                            {<CutButton />}
                        </React.Fragment>
                    )}
                    {clipboard && <PasteButton />}
                    {someChecked && (
                        <React.Fragment>
                            {checkedTags.length === 1 && !checkedTaggings.length && (
                                <RenameTagButton tag={checkedTags[0]} />
                            )}
                            {(checkedTaggings.length === 1 ||
                                currentTagging.id ||
                                taggingToShow.id) &&
                                !checkedTags.length && (
                                    <RenameTaggingButton
                                        tagging={
                                            checkedTaggings[0] || currentTagging || taggingToShow
                                        }
                                    />
                                )}
                            {<DeleteButton />}
                        </React.Fragment>
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
        </React.Fragment>
    );
};

export default Actions;
