// @flow
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse } from 'reactstrap';
import { Icon } from './Icon';
import { setExpandedDirectories } from '../_helpers';
import { useTaggings, useTags } from '../hooks';

export const Tree = (props) => {
    const { data: propsData, droppable } = props;
    const [data, setData] = useState(setExpandedDirectories(propsData));
    const { moveTaggings, taggings } = useTaggings();
    const { tags, updateTag } = useTags();

    useEffect(() => {
        setData(setExpandedDirectories(propsData));
    }, [propsData]);

    const toggleExpanded = (event) => {
        const target = event.currentTarget;
        const key = target.dataset.key;
        const _data = data._clone();
        _data[key].expanded = !_data[key].expanded;

        setData(_data);
    };

    const dragOver = (event) => {
        event.preventDefault();
        event.currentTarget.children[0].classList.add('drag-enter');
    };

    const dragEnter = (event) => {
        event.preventDefault();
        event.currentTarget.children[0].classList.add('drag-enter');
    };

    const dragLeave = (event) => {
        event.preventDefault();
        event.currentTarget.children[0].classList.remove('drag-enter');
    };

    const drop = async (event, tag) => {
        try {
            event.preventDefault();
            event.currentTarget.classList.remove('dragging');
            event.currentTarget.children[0].classList.remove('drag-enter');

            const { name: parentName } = tag;
            const { tag: draggingTag = {}, tagging: draggingTagging = {} } = JSON.parse(
                event.dataTransfer.getData('text')
            );
            const { node: draggingTaggingNode = {} } = draggingTagging;
            const { node: draggingTagNode = {} } = draggingTag;

            if (draggingTag.checked || draggingTagging.checked) {
                const draggingTags = tags.edges.filter(({ checked }) => checked);
                const draggingTaggings = taggings.edges.filter(({ checked }) => checked);

                draggingTags.forEach(({ node }) => {
                    const { id: tagId, title } = node;
                    const name = `${parentName}/${title}`.replace('//', '');
                    updateTag({ variables: { tagId, name } });
                });
                moveTaggings(draggingTaggings, { tag });
                return;
            }

            if (draggingTagNode.id) {
                if (tag.id === draggingTagNode.id) {
                    return;
                }
                const { id: tagId, title } = draggingTagNode;
                const name = `${parentName}/${title}`.replace('//', '');
                updateTag({ variables: { tagId, name } });
            }

            if (draggingTaggingNode.id) {
                moveTaggings([draggingTagging], { tag });
            }
        } catch (e) {
            throw new Error(e);
        }
    };

    const renderItem = (item) => {
        const { children, expanded, icon, id, to = '' } = item;
        return (
            <Link key={`Item-leaf-${id}`} to={to} data-cy="tree-item">
                <Button
                    color="light"
                    block
                    className={
                        to === encodeURI(decodeURI(window.location.pathname)) ? 'active' : ''
                    }
                    data-droppable={droppable}
                    onDragEnter={droppable && dragEnter}
                    onDragOver={droppable && dragOver}
                    onDragLeave={droppable && dragLeave}
                    onDrop={(event) => droppable && drop(event, item)}
                >
                    {(icon && icon.includes('.') && (
                        <img width="22" alt="avatar" src={icon} className="rounded-circle mr-2" />
                    )) || (
                        <Icon
                            type={
                                !expanded || !children || !children.length
                                    ? icon || 'folder'
                                    : icon || 'folder-open'
                            }
                            className="text-black-50"
                        />
                    )}
                    <span>{item.title}</span>
                </Button>
            </Link>
        );
    };

    return data.map(
        (item, i) =>
            (item.children && item.children.length && (
                <div key={`Leaf-${i}`} className={`tree${item.expanded ? ' expanded' : ''}`}>
                    <React.Fragment>
                        <Button
                            color="link"
                            data-key={i}
                            className="menu-button position-absolute m-0 p-0 ml--3"
                            onClick={toggleExpanded}
                        >
                            <Icon type="menu-right" className="text-black-50 m-0" />
                        </Button>
                        {renderItem(item)}
                    </React.Fragment>
                    <Collapse isOpen={item.expanded} className="ml-3">
                        <Tree {...props} data={item.children} />
                    </Collapse>
                </div>
            )) ||
            renderItem(item)
    );
};

export default Tree;
