import React from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { onError } from 'apollo-link-error';
import loggerLink from 'apollo-link-logger';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import ls from 'local-storage';
import { message, Progress } from 'antd';
import { debug, PAGES, URL } from '../_config';

function customFetch(url, opts = {}) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const { body, headers, method = 'get' } = opts;

        xhr.open(method, url);

        for (let k in headers || {}) xhr.setRequestHeader(k, opts.headers[k]);

        xhr.onload = (e) =>
            resolve({
                ok: true,
                text: () => Promise.resolve(e.target.responseText),
                json: () => Promise.resolve(JSON.parse(e.target.responseText)),
            });

        xhr.onerror = reject;

        if (xhr.upload && typeof body === 'object') {
            const file = body.getAll('1')[0] || {};
            const { name } = file;
            xhr.upload.onprogress = (event) => {
                const percentage = parseInt((event.loaded / event.total) * 100, 10);
                message.open({
                    content: (
                        <>
                            <Progress percent={percentage} status="active" />
                            <span className="mr-5">{name}</span>
                        </>
                    ),
                    duration: 0,
                    key: name,
                });
            };
            xhr.upload.onloadend = (event) => {
                message.open({
                    content: (
                        <>
                            <Progress percent={100} status="success" />
                            <span className="mr-5">{name}</span>
                        </>
                    ),
                    duration: 0,
                    key: name,
                });
                setTimeout(() => message.destroy(name), 2000);
            };
        }

        xhr.send(body);
    });
}

export const createApolloClient = () => {
    const cache = new InMemoryCache({
        cacheRedirects: {
            Query: {
                view: (_, { id }, { getCacheKey }) => getCacheKey({ __typename: 'User', id }),
            },
        },
    });

    const request = async (operation) => {
        const { auth = {} } = ls('user') || {};
        const { token } = auth;
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : '',
            },
        });
    };

    const requestLink = new ApolloLink(
        (operation, forward) =>
            new Observable((observer) => {
                let handle;
                Promise.resolve(operation)
                    .then((oper) => request(oper))
                    .then(() => {
                        handle = forward(operation).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    })
                    .catch(observer.error.bind(observer));

                return () => {
                    if (handle) handle.unsubscribe();
                };
            })
    );

    const logger = debug ? [loggerLink] : [];

    const client = new ApolloClient({
        link: ApolloLink.from([
            ...logger,
            onError(({ graphQLErrors, networkError }) => {
                if (graphQLErrors) {
                    const { auth: { token } = {} } = ls('user') || {};
                    if (
                        token &&
                        graphQLErrors.some(
                            ({ extensions: { category } = {} } = {}) =>
                                category === 'authentication'
                        )
                    ) {
                        ls.remove('user');
                        window.location.href = `${URL.DOYO}${PAGES.EXIT}/${URL.FACE}`;
                    }
                    console.error('graphQLErrors >>>', graphQLErrors);
                    //sendToLoggingService(graphQLErrors);
                }
                if (networkError) {
                    console.error('networkError >>>', networkError);
                    //logoutUser();
                }
            }),
            requestLink,
            withClientState({
                defaults: {
                    isConnected: true,
                },
                resolvers: {
                    Mutation: {
                        updateNetworkStatus: (_, { isConnected }, { cache }) => {
                            cache.writeData({ data: { isConnected } });
                            return null;
                        },
                    },
                },
                cache,
            }),
            new RetryLink().split(
                (operation) => operation.getContext().passport,
                new HttpLink({
                    uri: URL.PASSPORT,
                    credentials: 'include',
                }),
                createUploadLink({
                    uri: URL.API,
                    credentials: 'include',
                    fetch: typeof window === 'undefined' ? global.fetch : customFetch,
                })
            ),
        ]),
        cache,
    });

    return client;
};
