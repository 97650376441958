import ls from 'local-storage';

const user = ls('user') || {};
const { preferences = { directories: { view: 'list' } } } = user;
export const VIEWER = {
    INITIAL_STATE: {
        viewer: undefined,
        preferences,
    },

    CREATE: 'VIEWER_CREATE',
    GET: 'VIEWER_GET',
    UPDATE: 'VIEWER_UPDATE',
    ERROR: 'VIEWER_ERROR',
    DIRECTORIES_VIEW: 'VIEWER_DIRECTORIES_VIEW',
};

export const ViewerReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case VIEWER.GET:
            draft.viewer = payload.viewer;
            delete draft.error;
            break;

        case VIEWER.ERROR:
            draft.error = payload.error;
            break;

        case VIEWER.DIRECTORIES_VIEW:
            const user = ls('user') || {};
            draft.preferences = {
                ...draft.preferences,
                directories: { ...draft.preferences.directories, view: payload.view },
            };
            user.preferences = draft.preferences;
            ls('user', user);
            break;

        default:
            return draft;
    }
};
