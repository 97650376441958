import React from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from 'antd';
import { Icon, PlaceholderImage, PlaceholderText } from '../../components';
import { mobileCheck, findInTree } from '../../_helpers';
import { useTaggings, useTags } from '../../hooks';

const isMobile = mobileCheck();

export const Tags = ({ handleInputClick = () => {} }) => {
    const { moveTaggings } = useTaggings();
    const { taggings = { edges: [] } } = useTaggings();
    const {
        checkTags,
        current: currentTag = {},
        hierarchical = [],
        uncheckTags,
        tags = { edges: [] },
        updateTag,
    } = useTags();

    const dragStart = (event, tag) => {
        event.currentTarget.classList.add('dragging');
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.setData('text/plain', JSON.stringify({ tag }));
    };

    const dragEnter = (event) => {
        event.preventDefault();
        if (event.currentTarget.classList.contains('dragging')) {
            return;
        }
        event.currentTarget.children[0].classList.add('drag-enter');
    };

    const dragLeave = (event) => {
        event.preventDefault();
        event.currentTarget.children[0].classList.remove('drag-enter');
    };

    const drop = async (event, { node: tag }) => {
        try {
            event.preventDefault();
            event.currentTarget.classList.remove('dragging');
            event.currentTarget.children[0].classList.remove('drag-enter');

            const { name: parentName } = tag;
            const { tag: draggingTag = {}, tagging: draggingTagging = {} } = JSON.parse(
                event.dataTransfer.getData('text')
            );
            const { node: draggingTaggingNode = {} } = draggingTagging;
            const { node: draggingTagNode = {} } = draggingTag;

            if (draggingTag.checked || draggingTagging.checked) {
                const draggingTags = tags.edges.filter(({ checked }) => checked);
                const draggingTaggings = taggings.edges.filter(({ checked }) => checked);

                draggingTags.forEach(({ node }) => {
                    const { id: tagId, title } = node;
                    const name = `${parentName}/${title}`.replace('//', '');
                    updateTag({ variables: { tagId, name } });
                });
                moveTaggings(draggingTaggings, { tag });
                return;
            }

            if (draggingTagNode.id) {
                if (tag.id === draggingTagNode.id) {
                    return;
                }
                const { id: tagId, title } = draggingTagNode;
                const name = `${parentName}/${title}`.replace('//', '');
                updateTag({ variables: { tagId, name } });
            }

            if (draggingTaggingNode.id) {
                moveTaggings([draggingTagging], { tag });
            }
        } catch (e) {
            throw new Error(e);
        }
    };

    const handleTagsCheck = (event, tag) => {
        const { checked } = event.target;
        if (checked) {
            checkTags([tag]);
        } else {
            uncheckTags([tag]);
        }
    };

    const renderPlaceholder = () => {
        return (
            <React.Fragment>
                <tr>
                    <td className="align-middle">
                        <PlaceholderImage
                            color="gray"
                            className="mr-2"
                            style={{ width: '16px', height: '16px' }}
                        />
                    </td>
                    <td className="py-4 align-middle">
                        <div className="d-flex">
                            <PlaceholderImage
                                color="primary"
                                className="mr-2"
                                style={{ width: '30px' }}
                            />
                            <PlaceholderText style={{ width: '130px' }} />
                        </div>
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '120px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile" />
                </tr>
                <tr>
                    <td className="align-middle">
                        <PlaceholderImage
                            color="gray"
                            className="mr-2"
                            style={{ width: '16px', height: '16px' }}
                        />
                    </td>
                    <td className="py-4 align-middle">
                        <div className="d-flex">
                            <PlaceholderImage
                                color="primary"
                                className="mr-2"
                                style={{ width: '30px' }}
                            />
                            <PlaceholderText style={{ width: '200px' }} />
                        </div>
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '120px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile" />
                </tr>
                <tr>
                    <td className="align-middle">
                        <PlaceholderImage
                            color="gray"
                            className="mr-2"
                            style={{ width: '16px', height: '16px' }}
                        />
                    </td>
                    <td className="py-4 align-middle">
                        <div className="d-flex">
                            <PlaceholderImage
                                color="primary"
                                className="mr-2"
                                style={{ width: '30px' }}
                            />
                            <PlaceholderText style={{ width: '160px' }} />
                        </div>
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '120px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile" />
                </tr>
            </React.Fragment>
        );
    };

    const { children = [] } = findInTree(currentTag, hierarchical) || {};
    const parentTagName =
        currentTag.id && currentTag.name.includes('/')
            ? currentTag.name.replace(/\/[^/]*$/, '')
            : '/';
    const someChecked =
        tags.edges.some(({ checked }) => checked) || taggings.edges.some(({ checked }) => checked);
    const parent = tags.edges.find(({ node }) => node.name === parentTagName) || {};
    const { node: parentNode = {} } = parent;
    const { id: parentId, name: parentName, to: parentTo = '' } = parentNode;
    if (!tags.edges.length) {
        return renderPlaceholder();
    }

    return (
        <React.Fragment>
            {(parentName && (
                <tr>
                    <td className="align-middle">
                        <Link to={parentTo} className="d-block">
                            <Icon type="arrow-up" />
                        </Link>
                    </td>
                    <td className="align-middle">
                        <Link to={parentTo} className="d-block" draggable={false}>
                            <div
                                className="d-flex align-items-center"
                                data-droppable={!!parentId}
                                onDragEnter={parentId && dragEnter}
                                onDragOver={parentId && dragEnter}
                                onDragLeave={parentId && dragLeave}
                                onDrop={(event) => parentId && drop(event, parent)}
                            >
                                <Icon type="folder" className="text-primary font-size-26 mr-2" />
                                <span>..</span>
                            </div>
                        </Link>
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile" />
                    <td className="align-middle" data-hide-on="grid,mobile" />
                </tr>
            )) ||
                null}
            {((currentTag.id && children) || hierarchical[0].children)
                .map((h, i) => {
                    const tag = tags.edges.find(({ node: tag }) => tag.id === h.id);
                    if (!tag) {
                        return null;
                    }
                    const { checked, cuted, node } = tag;
                    const { createdAt, id, title, to } = node;
                    const creationDate = new Date(createdAt).toLocaleString();

                    return (
                        <tr
                            key={`Tag-${i}`}
                            className="tag"
                            data-checked={checked}
                            data-some-checked={someChecked}
                            data-cy="directory"
                        >
                            <td className="align-middle">
                                <Checkbox
                                    id={`Directory-${id}`}
                                    onChange={(event) => handleTagsCheck(event, tag)}
                                    onClick={handleInputClick}
                                    checked={checked}
                                />
                            </td>
                            <td className="align-middle">
                                <Link
                                    to={to}
                                    className={`d-block${cuted ? ' opacity-0_2' : ''}`}
                                    draggable={false}
                                    onClick={(event) => {
                                        if (someChecked) {
                                            event.preventDefault();
                                            document.getElementById(`Directory-${id}`).click();
                                        }
                                    }}
                                    onContextMenu={(event) => {
                                        isMobile && event.preventDefault();
                                    }}
                                >
                                    <div
                                        className="d-flex align-items-center"
                                        draggable={true}
                                        onDragStart={(event) => dragStart(event, tag)}
                                        onDragEnd={(event) =>
                                            event.currentTarget.classList.remove('dragging')
                                        }
                                        data-droppable={true}
                                        onDragEnter={(event) => dragEnter(event, tag)}
                                        onDragOver={(event) => dragEnter(event, tag)}
                                        onDragLeave={dragLeave}
                                        onDrop={(event) => drop(event, tag)}
                                    >
                                        <Icon
                                            type="folder"
                                            className="position-relative text-primary font-size-26 mr-2"
                                        ></Icon>
                                        <span>{title}</span>
                                    </div>
                                </Link>
                            </td>
                            <td className="align-middle" data-hide-on="grid,mobile" />
                            <td className="align-middle" data-hide-on="grid,mobile" />
                            <td className="align-middle" data-hide-on="grid,mobile">
                                <small className="text-muted">{creationDate}</small>
                            </td>
                            <td className="align-middle" data-hide-on="grid,mobile" />
                        </tr>
                    );
                })
                .filter((tag) => !!tag)}
        </React.Fragment>
    );
};

export default Tags;
