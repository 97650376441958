// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Nav, NavItem } from 'reactstrap';
import { Icon } from './Icon';

const SidebarMenu = (props) => {
    const { active, children, className } = props;

    return (
        <nav className={`sidebar sidebar-menu bg-light ${className ? className : ''}`}>
            <Nav vertical className="px-3 flex-nowrap">
                <li className="mt-3 pb-3">
                    {
                        <span className="btn btn-link cursor-default text-primary font-weight-bold mt-1 mb-0 text-left">
                            <Icon type="star-outline" className="mr-1" />
                            <span>Kustodio Plus</span>
                        </span>
                    }
                </li>
                {children.map(
                    (child, i) =>
                        (child.type === NavItem && child) || (
                            <NavItem key={`Child-${i}`}>
                                <Link to={child.props.to}>
                                    <Button
                                        color="light"
                                        block
                                        className={
                                            child.props.to === (active || window.location.pathname)
                                                ? 'active'
                                                : ''
                                        }
                                    >
                                        {child.props.children[0]}
                                        {child.props.children[1]}
                                    </Button>
                                </Link>
                            </NavItem>
                        )
                )}
            </Nav>
        </nav>
    );
};

export default SidebarMenu;
export { SidebarMenu };
