// @flow
import React, { useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import ls from 'local-storage';
import { Button, Col, Container, Jumbotron, Row } from 'reactstrap';
import { PAGES } from '../../_config';
import { Footer, Header } from '../../components';
import {
    balloon,
    bannerBg,
    edit,
    floppy,
    lock,
    security,
    ship,
    wallet,
} from '../../assets/svg/home';

export const HomePage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { pathname } = location;

    useEffect(() => {
        if (pathname === '/') {
            const { auth = {} } = ls('user') || {};
            const { token } = auth;
            history.push(token ? PAGES.TAGS : PAGES.HOME);
        }
    }, []);

    return (
        <main className="home">
            <Helmet
                title={t('seo.titles.home')}
                meta={[
                    {
                        name: 'description',
                        content: t('seo.descriptions.home'),
                    },
                ]}
            />
            <Header />
            <Jumbotron
                fluid
                className="position-relative space-2"
                style={{ backgroundImage: `url('${bannerBg}')` }}
            >
                <Container>
                    <Row className="align-items-lg-center">
                        <Col lg={{ size: 5 }} className="mb-lg-0">
                            <h1 className="display-4 font-size-48--md-down font-weight-bold">
                                {t('home.banner.title')}
                            </h1>
                            <p>{t('home.banner.subtitle')}</p>
                            <Link to={PAGES.SIGN_UP}>
                                <Button color="primary" className="card-link">
                                    {t('home.banner.button')}
                                </Button>
                            </Link>
                        </Col>
                        <Col
                            xs={{ size: 7 }}
                            className="banner-fluid text-right ml-auto px-2 px-lg-5"
                        >
                            <img src={security} alt="SVG Illustration" />
                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
            <Container className="marketing">
                <Row>
                    <Col lg={{ size: 4 }}>
                        <img src={floppy} alt="Icon 1" width="140" height="140" />
                        <h2>{t('home.block1.title1')}</h2>
                        <p>{t('home.block1.text1')}</p>
                    </Col>
                    <Col lg={{ size: 4 }}>
                        <img src={edit} alt="Icon 3" width="140" height="140" />
                        <h2>{t('home.block1.title2')}</h2>
                        <p>{t('home.block1.text2')}</p>
                    </Col>
                    <Col lg={{ size: 4 }}>
                        <img src={ship} alt="Icon 3" width="140" height="140" />
                        <h2>{t('home.block1.title3')}</h2>
                        <p>{t('home.block1.text3')}</p>
                    </Col>
                </Row>

                <hr className="featurette-divider" />

                <Row className="featurette">
                    <Col md={{ size: 7 }}>
                        <h2 className="featurette-heading">{t('home.features.title1')}</h2>
                        <p className="lead">{t('home.features.text1')}</p>
                    </Col>
                    <Col md={{ size: 5 }}>
                        <img
                            alt="feature 2"
                            className="featurette-image img-fluid mx-auto h-auto"
                            src={balloon}
                        />
                    </Col>
                </Row>
                <hr className="featurette-divider" />
                <Row className="featurette">
                    <Col md={{ size: 7 }}>
                        <h2 className="featurette-heading">{t('home.features.title2')}</h2>
                        <p className="lead">{t('home.features.text2')}</p>
                    </Col>
                    <Col md={{ size: 5, order: 1 }}>
                        <img
                            alt="feature 2"
                            className="featurette-image img-fluid mx-auto h-auto"
                            src={lock}
                        />
                    </Col>
                </Row>
                <hr className="featurette-divider" />
                <Row className="featurette">
                    <Col md={{ size: 7 }}>
                        <h2 className="featurette-heading">{t('home.features.title3')}</h2>
                        <p className="lead">{t('home.features.text3')}</p>
                    </Col>
                    <Col md={{ size: 5 }}>
                        <img
                            alt="feature 3"
                            className="featurette-image img-fluid mx-auto h-auto"
                            src={wallet}
                        />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </main>
    );
};

export default HomePage;
