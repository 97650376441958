// @flow
import ls from 'local-storage';
import { useHistory } from 'react-router-dom';
import { PAGES } from '../../_config';

function LoginPage({
    match: {
        params: { token, redirect },
    },
}) {
    const history = useHistory();
    let user = ls('user') || {};
    user = { ...user, auth: { token } };
    ls('user', user);
    history.replace(redirect || PAGES.TAGS);
    return null;
}

export default LoginPage;
export { LoginPage };
