import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import parse from 'html-react-parser';
import {
    Button as ReactStrapButton,
    Card,
    CardBody,
    CardFooter,
    CardText,
    CardTitle,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    UncontrolledDropdown,
    UncontrolledTooltip,
} from 'reactstrap';
import { getLanguage, getRgba, getTextColor, isObject, unzip } from '../_helpers';
import { GoogleMap, Icon, RangeSlider, Youtube as YoutubeVideo } from '../components';

const backgroundTypes = {
    TRANSPARENT: 'transparent',
    SOLID: 'solid',
    GRADIENT: 'gradient',
    IMAGE: 'image',
};
const componentTypes = {
    GROUP: 'group',
    IMAGE: 'image',
    PDFVIEWER: 'pdfviewer',
    TEXT: 'text',
    VIDEO: 'video',
    WEB: 'web',
    YOUTUBE: 'youtube',
};
const halignTypes = {
    START: 'start',
    CENTER: 'center',
    END: 'end',
};
const marginTypes = {
    BOTTOM: 'bottom',
    END: 'end',
    START: 'start',
    TOP: 'top',
};
const orientationTypes = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical',
};
const valignTypes = {
    TOP: 'top',
    MIDDLE: 'middle',
    BOTTOM: 'bottom',
};
const marginKeys = {
    [marginTypes.TOP]: 'Top',
    [marginTypes.BOTTOM]: 'Bottom',
    [marginTypes.START]: 'Left',
    [marginTypes.END]: 'Right',
};
const paddingKeys = marginKeys;
const borderKeys = marginKeys;

const useMutationObserver = () => {};

export const PreviewContext = createContext({});

const useFile = (key) => {
    const { data } = useContext(PreviewContext);

    if (!key) {
        return {};
    }

    const file = data.res.find(({ name }) => name === `res/${key}`) || {};
    return file;
};

const allKeys = ['@app', '@color', '@menu'];

const useSource = (key) => {
    const { data } = useContext(PreviewContext);
    let value;

    if (!key) {
        return { value, keys: allKeys };
    }

    const keys = allKeys.filter((k) => k.includes(key));

    if (key.includes('@')) {
        if (key.includes('@app.')) {
            key = key.replace('@app.', '@app.android.');
        }
        if (key.includes('@color.')) {
            key = key.replace('@color.', '@app.colors.');
        }
        if (key.includes('@font.')) {
            key = key.replace('@font.', '@app.fonts.');
        }
        if (key.includes('@menu.')) {
            key = key.replace('@menu.', '@menus.');
        }
        const subKeys = key.replace('@', '').split('.');
        value =
            subKeys.reduce(
                (value, subKey, i) =>
                    (subKey === 'index' && parseInt(subKeys[i - 1], 10) + 1) ||
                    (value && value[subKey]) ||
                    (Array.isArray(value) && value.find(({ id }) => id === subKey)) ||
                    (value && value.items && value.items[subKey]),
                data
            ) || value;
    }

    return { value, keys };
};

const getBackground = (background) => {
    const { type, color, direction = '', from, image, to } = background;
    switch (type) {
        case backgroundTypes.TRANSPARENT:
            return {};
        case backgroundTypes.SOLID:
            return { backgroundColor: `${getRgba(color) || ''}`.trim() };
        case backgroundTypes.GRADIENT:
            return {
                background: `linear-gradient(${direction.replace(/_/g, ' ')}, ${from}, ${to})`,
            };
        case backgroundTypes.IMAGE:
            return { backgroundImage: `url(${image})` };
        default:
            return {};
    }
};

const getBorder = (borderColor = '') => {
    if (typeof borderColor === 'string') {
        return { border: `1px solid ${borderColor}` };
    }

    let style = {};
    Object.keys(borderColor).forEach((key) => {
        const _key = borderKeys[key];
        let color = borderColor[key];
        if (color.includes('@')) {
            const { value = '' } = useSource(color);
            color = value;
        }
        return (style = {
            ...style,
            [`border${`${_key}`}`]: `1px solid ${color}`,
        });
    });
    return style;
};

const getAlign = (align) => {
    switch (align) {
        case halignTypes.START:
            return 'flex-start';
        case halignTypes.CENTER:
            return 'center';
        case halignTypes.END:
            return 'flex-end';
        default:
            return 'flex-start';
    }
};
const getHalign = getAlign;

const getValign = (align) => {
    switch (align) {
        case valignTypes.TOP:
            return 'flex-start';
        case valignTypes.MIDDLE:
            return 'center';
        case valignTypes.BOTTOM:
            return 'flex-end';
        default:
            return 'center';
    }
};

const getHeight = (height) => {
    height =
        `${height}`.includes('%') || isNaN(parseInt(height, 10))
            ? height
            : `${`${height}`.replace('dp', '')}px`;
    return { height, minHeight: height, maxHeight: height };
};

const getMargin = (margin = '') => {
    if (typeof margin === 'number') {
        return { margin: `${margin}px` };
    }
    if (typeof margin === 'string') {
        return { margin: margin.replace('dp', 'px') };
    }

    let style = {};
    Object.keys(margin).forEach((key) => {
        const _key = marginKeys[key];
        const numberStr =
            typeof margin[key] === 'number' ? `${margin[key]}px` : margin[key].replace('dp', '');
        return (style = {
            ...style,
            [`margin${`${_key}`}`]: numberStr,
        });
    });
    return style;
};
const getPadding = (padding = '') => {
    if (typeof padding === 'number') {
        return { padding: `${padding}px` };
    }
    if (typeof padding === 'string') {
        return { padding: padding.replace('dp', 'px') };
    }

    let style = {};
    Object.keys(padding).forEach((key) => {
        const _key = paddingKeys[key];
        const numberStr =
            typeof padding[key] === 'number' ? `${padding[key]}px` : padding[key].replace('dp', '');
        return (style = {
            ...style,
            [`padding${`${_key}`}`]: numberStr,
        });
    });
    return style;
};

const getVisibility = (visible) => {
    const visibility = visible ? 'visible' : 'hidden';
    const display = !visible && 'none';
    return { visibility, display };
};

const getStyle = (attributes) => {
    const {
        background,
        border,
        height,
        margin,
        onclick,
        padding,
        position,
        visible,
        width,
    } = attributes;

    let style = {};
    style = typeof background !== 'undefined' ? { ...style, ...getBackground(background) } : style;
    style = typeof border !== 'undefined' ? { ...style, ...getBorder(border) } : style;
    style = typeof height !== 'undefined' ? { ...style, ...getHeight(height) } : style;
    style = typeof margin !== 'undefined' ? { ...style, ...getMargin(margin) } : style;
    style =
        typeof onclick !== 'undefined'
            ? {
                  ...style,
                  cursor: onclick[0] && onclick[0].function === 'browser' ? 'pointer' : 'default',
              }
            : style;
    style = typeof padding !== 'undefined' ? { ...style, ...getPadding(padding) } : style;
    style = typeof position !== 'undefined' ? { ...style, position } : style;
    style = typeof visible !== 'undefined' ? { ...style, ...getVisibility(visible) } : style;
    style = typeof width !== 'undefined' ? { ...style, ...getWidth(width) } : style;

    return style;
};

const getWidth = (width) => {
    width =
        `${width}`.includes('%') || isNaN(parseInt(width, 10))
            ? width
            : `${`${width}`.replace('dp', '')}px`;
    return { width, minWidth: width, maxWidth: width };
};

const Wrapper = ({ children, className = '', ...rest }) => {
    const { halign, height, id, onclick = [], orientation, position, type, valign, width } = rest;
    const flexDirection =
        orientation && orientation === orientationTypes.HORIZONTAL ? 'row' : 'column';
    let { background = {}, borderColor = '' } = rest;
    if (typeof background === 'string') {
        background = { type: backgroundTypes.SOLID, color: background };
    }
    const { color, from, to, image } = background;
    const { value: _color } = useSource(color);
    const { value: _from } = useSource(from);
    const { value: _to } = useSource(to);
    const { value: _borderColor = '' } = useSource(borderColor);
    const { url } = useFile(image);

    if (color && color.includes('@')) {
        background = { ...background, color: _color };
    }
    if (from && from.includes('@')) {
        background = { ...background, from: _from };
    }
    if (to && to.includes('@')) {
        background = { ...background, to: _to };
    }
    if (borderColor.includes('@')) {
        borderColor = _borderColor;
    }
    if (image) {
        background = { ...background, image: url };
    }
    const attributes = { ...rest, background, borderColor };
    const active = document.querySelector(`[data-component-id="${id}"].active`);
    if (active && !className.includes('active')) {
        className = `${className} active`.trim();
    }
    return (
        <div
            className={`component-wrapper ${className}`}
            data-component-id={id || ''}
            data-direction={flexDirection}
            data-halign={getHalign(halign)}
            data-height={height}
            data-position={position}
            data-type={type}
            data-valign={getValign(valign)}
            data-width={width}
            style={getStyle(attributes)}
            onClick={(event) => {
                if (onclick[0] && onclick[0].function === 'browser') {
                    const a = document.createElement('a');
                    a.href = onclick[0] && onclick[0].url;
                    a.target = (onclick[0] && onclick[0].target) || '_blank';
                    a.click();
                }
            }}
        >
            {children}
        </div>
    );
};

const compareProps = (prevProps, nextProps) => prevProps._equals(nextProps);

const Group = React.memo((attributes) => {
    const { data_source: dataSource, orientation, scroll } = attributes;
    let { content = [] } = attributes;
    content = Array.isArray(content) ? content.filter((child) => child) : [];
    const flexDirection =
        orientation && orientation === orientationTypes.HORIZONTAL ? 'row' : 'column';
    const contentWithDataSource = content.map((component, index) => {
        const { field } = component || {};
        return {
            ...component,
            source: `${dataSource}.${field}`,
            index,
        };
    });

    return (
        <Wrapper {...attributes}>
            {(content && content.length && (
                <div className="visor" data-direction={flexDirection} data-scroll={scroll}>
                    <Preview content={dataSource ? contentWithDataSource : content} />
                </div>
            )) ||
                null}
        </Wrapper>
    );
}, compareProps);

const Image = React.memo((attributes) => {
    const language = getLanguage();
    const { file, height, id, scale } = attributes;

    let fileToShow = file;
    if (isObject(fileToShow)) {
        fileToShow =
            typeof fileToShow[language] !== 'undefined' ? fileToShow[language] : fileToShow.default;
    }
    if (typeof fileToShow === 'undefined' || fileToShow === '') {
        fileToShow = '?';
    }

    const { url = fileToShow } = useFile(fileToShow);
    const style = {};
    const dataScale = height !== 'wrap' && scale;

    if (dataScale) {
        style.backgroundImage = `url(${url})`;
    }
    return (
        <Wrapper {...attributes}>
            <div data-scale={dataScale} style={style}>
                <img src={url} alt={id} />
            </div>
        </Wrapper>
    );
}, compareProps);

const PdfViewer = React.memo((attributes) => {
    const language = getLanguage();
    const { id, file } = attributes;

    let fileToShow = file;
    if (isObject(fileToShow)) {
        fileToShow =
            typeof fileToShow[language] !== 'undefined' ? fileToShow[language] : fileToShow.default;
    }
    if (typeof fileToShow === 'undefined' || fileToShow === '') {
        fileToShow = '';
    }

    const { url = fileToShow } = useFile(fileToShow);

    return (
        <Wrapper {...attributes}>
            <iframe title={id} src={url} />
        </Wrapper>
    );
}, compareProps);

const Text = React.memo((attributes) => {
    const language = getLanguage();
    const { font, source, text_valign: textValign } = attributes;
    let {
        color = '@color.primaryText',
        font_size: fontSize = '',
        text,
        text_align: textAlign = 'start',
    } = attributes;
    fontSize = `${`${fontSize}`.replace('dp', '')}px`;
    textAlign = getAlign(textAlign);
    const { value: sourceText = '' } = useSource(source);
    const { value: sourceColor = '' } = useSource(color);
    const { value: sourceFont = '' } = useSource(font);
    const { url = '' } = useFile(sourceFont);

    let textToShow = text;
    if (source && source.includes('@')) {
        textToShow = sourceText || text;
    }

    if (color && color.includes('@')) {
        color = sourceColor;
    }

    let fontUrl;
    let fontFamily;
    if (font) {
        fontFamily = font.replace('@font.', '');
        fontUrl = url;
    }

    if (isObject(textToShow)) {
        textToShow =
            typeof textToShow[language] !== 'undefined' ? textToShow[language] : textToShow.default;
    }
    if (typeof textToShow === 'undefined' || textToShow === '') {
        textToShow = '?';
    }

    return (
        <Wrapper {...attributes}>
            {(fontUrl && (
                <style>{`
            @font-face {
                font-family: ${fontFamily};
                src: url(${fontUrl});
            }
        `}</style>
            )) ||
                null}
            <div
                data-align={textAlign}
                data-valign={textValign}
                style={{ color, fontFamily, fontSize }}
            >
                {parse(`${textToShow}`)}
            </div>
        </Wrapper>
    );
}, compareProps);

const Video = React.memo((attributes) => {
    const language = getLanguage();
    const { autoplay, file, height, loop, scale } = attributes;
    const dataScale = height !== 'wrap' && scale;

    let fileToShow = file;
    if (isObject(fileToShow)) {
        fileToShow =
            typeof fileToShow[language] !== 'undefined' ? fileToShow[language] : fileToShow.default;
    }
    if (typeof fileToShow === 'undefined' || fileToShow === '') {
        fileToShow = '';
    }

    const { url = fileToShow } = useFile(fileToShow);
    const videoProps = { src: url };
    autoplay && (videoProps.autoPlay = true);
    loop && (videoProps.loop = true);

    return (
        <Wrapper {...attributes}>
            <div data-scale={dataScale}>
                <video
                    {...videoProps}
                    onLoadedData={(event) => {
                        if (!dataScale) {
                            return;
                        }
                        const { currentTarget } = event;
                        const { parentElement, videoHeight, videoWidth } = currentTarget;
                        const {
                            height: parentHeight,
                            width: parentWidth,
                        } = parentElement.getBoundingClientRect();
                        if (
                            Math.abs(parentWidth / videoWidth) >
                            Math.abs(parentHeight / videoHeight)
                        ) {
                            currentTarget.style.width = `${parentWidth}px`;
                            currentTarget.style.height = `${(videoHeight *
                                ((parentWidth * 100) / videoWidth)) /
                                100}px`;
                        } else {
                            currentTarget.style.height = `${parentHeight}px`;
                            currentTarget.style.width = `${(videoWidth *
                                ((parentHeight * 100) / videoHeight)) /
                                100}px`;
                        }
                        // currentTarget.dataset.vertical = videoHeight > videoWidth;
                    }}
                />
            </div>
        </Wrapper>
    );
}, compareProps);

const Web = React.memo((attributes) => {
    const language = getLanguage();
    const { file, id, url } = attributes;
    const iframe = useRef();
    const props = {};
    const mutationProps = {};

    let urlToShow = url;
    if (isObject(urlToShow)) {
        urlToShow =
            typeof urlToShow[language] !== 'undefined' ? urlToShow[language] : urlToShow.default;
    }
    if (typeof urlToShow === 'undefined' || urlToShow === '') {
        urlToShow = '';
    }

    let fileToShow = file;
    if (isObject(fileToShow)) {
        fileToShow =
            typeof fileToShow[language] !== 'undefined' ? fileToShow[language] : fileToShow.default;
    }
    if (typeof fileToShow === 'undefined' || fileToShow === '') {
        fileToShow = '';
    }
    const resource = useFile(fileToShow);

    if (/^https:\/\//.test(urlToShow)) {
        props.src = urlToShow;
    } else {
        mutationProps.resource = resource;
        mutationProps.init = urlToShow || undefined;
    }

    useMutationObserver(iframe, mutationProps);

    useEffect(() => {
        unzip(resource);
    }, [resource]);

    return (
        <Wrapper {...attributes}>
            <iframe title={id} {...props} ref={iframe} />
        </Wrapper>
    );
}, compareProps);

const Youtube = React.memo((attributes) => {
    const language = getLanguage();
    const { video_id: videoId } = attributes;
    const { data = {} } = useContext(PreviewContext);
    const { app = {} } = data;
    const { android = {} } = app;
    const { youtube_api_key: apiKey = '' } = android;

    let videoIdToShow = videoId;
    if (isObject(videoIdToShow)) {
        videoIdToShow =
            typeof videoIdToShow[language] !== 'undefined'
                ? videoIdToShow[language]
                : videoIdToShow.default;
    }
    if (typeof videoIdToShow === 'undefined' || videoIdToShow === '') {
        videoIdToShow = '';
    }

    return (
        <Wrapper {...attributes}>
            <YoutubeVideo videoId={videoIdToShow} />
        </Wrapper>
    );
}, compareProps);

const Component = (attributes) => {
    const { type } = attributes;

    switch (type) {
        case componentTypes.GROUP:
            return <Group {...attributes} />;
        case componentTypes.IMAGE:
            return <Image {...attributes} />;
        case componentTypes.PDFVIEWER:
            return <PdfViewer {...attributes} />;
        case componentTypes.TEXT:
            return <Text {...attributes} />;
        case componentTypes.VIDEO:
            return <Video {...attributes} />;
        case componentTypes.WEB:
            return <Web {...attributes} />;
        case componentTypes.YOUTUBE:
            return <Youtube {...attributes} />;
        default:
            return null;
    }
};

export const Preview = ({ content }) => {
    if (!content) {
        return null;
    }
    content = Array.isArray(content) ? content : [];
    return (
        <>
            {content.map((attributes, i) => {
                const { halign, height, position, valign, width } = attributes;
                const styleAttributes = { height, width, position };
                return (
                    <div
                        key={`component-${i}-${Date.now()}`}
                        style={getStyle(styleAttributes)}
                        data-halign={getHalign(halign)}
                        data-position={position}
                        data-valign={getValign(valign)}
                    >
                        <Component {...attributes} />
                    </div>
                );
            })}
        </>
    );
};

const MainContent = ({ value }) => {
    let views = [value];
    return (
        <div className="main-content">
            {views.map((view) => (
                <Group key={`main-content-${view.id}`} {...view} />
            ))}
        </div>
    );
};

export const PreviewWrapper = ({ entries = [] }) => {
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const { landing = {} } = data;
    const { title, description } = landing;

    useEffect(() => {
        let mounted = true;
        (async () => {
            const res = entries.filter(({ name }) => /^res\//.test(name));
            let { url: appUrl } = entries.find(({ name }) => name === 'app.json') || {};
            let { url: landingUrl } = entries.find(({ name }) => name === 'landing.json') || {};
            const app = appUrl ? await (await fetch(appUrl)).json() : {};
            const landing = landingUrl ? await (await fetch(landingUrl)).json() : {};
            mounted &&
                setData((data) => ({ ...data, app, landing: { id: 'landing', ...landing }, res }));
        })();
        return () => {
            mounted = false;
        };
    }, []);

    return (
        <PreviewContext.Provider value={{ data }}>
            <Helmet
                title={`${title} - made by Codoozer`}
                meta={[
                    {
                        name: 'description',
                        content: description,
                    },
                ]}
            />
            <div className="preview-wrapper">
                <div>
                    <div className="content">
                        <MainContent value={landing} data={data} />
                    </div>
                </div>
            </div>
        </PreviewContext.Provider>
    );
};

export default PreviewWrapper;
