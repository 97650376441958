import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import ls from 'local-storage';
import { Button } from 'reactstrap';
import { PAGES } from '../../_config';
import { CustomModal, FilePreview } from '../../components';
import { Actions } from '../DirectoriesPage';
import { useTaggings } from '../../hooks';
import { logo } from '../../assets/svg/logos';

export const SharingPage = ({ match }) => {
    const { t } = useTranslation();
    const { file, token } = match.params;
    const id = token || file;
    const history = useHistory();
    const { showTagging, taggingToShow = {} } = useTaggings();

    useEffect(() => {
        showTagging({ variables: { id } });
    }, []);

    const { auth = {} } = ls('user') || {};
    const { token: loggedIn } = auth;
    const { tag = {} } = taggingToShow;
    const { name } = tag;
    const to = file ? encodeURI(`${PAGES.TAGS}${name && name !== '/' ? `/${name}` : ''}`) : '/';
    const header = (
        <React.Fragment>
            <Actions download={true} />
            {!loggedIn && (
                <Link to={PAGES.SIGN_UP} className="ml-3">
                    <Button
                        color="primary"
                        block
                        className="text-uppercase font-weight-bold font-size-10"
                    >
                        {t('common.sign_up_long')}
                    </Button>
                </Link>
            )}
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <Helmet
                title={t('seo.titles.home')}
                meta={[
                    {
                        name: 'description',
                        content: t('seo.descriptions.home'),
                    },
                ]}
            />
            {(taggingToShow.id && (
                <CustomModal
                    className="preview-modal"
                    header={header}
                    isOpen={true}
                    title={taggingToShow.name}
                    toggle={() => history.push(to)}
                >
                    <Link className="powered" to="/">
                        <small>Powered by</small>
                        <img alt="Kustodio logo" src={logo} />
                    </Link>
                    <FilePreview {...taggingToShow.file} name={taggingToShow.name} />
                </CustomModal>
            )) ||
                null}
        </React.Fragment>
    );
};

export default SharingPage;
