import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import debounce from 'just-debounce-it';
import { Checkbox, Tooltip } from 'antd';
import {
    Button,
    Collapse,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
    Input,
    Label,
    Table,
    UncontrolledDropdown,
} from 'reactstrap';
import { fileBlackList, findDirectoryByPathname, mobileCheck } from '../../_helpers';
import { Actions, DirectoriesMenu, Taggings, Tags } from '.';
import {
    CustomModal,
    DirectorySelect,
    FilePreview,
    Icon,
    ProgressIndeterminate,
    SearchBox,
    Sidebar,
} from '../../components';

import {
    useAccounts,
    useClipboard,
    useNearScreen,
    useTaggings,
    useTags,
    useViewer,
} from '../../hooks';

const isMobile = mobileCheck();

const renderRootPlaceholder = () => {
    return (
        <tr>
            <td className="py-4 align-middle" colSpan={5}>
                <div className="placeholder-fullscreen">
                    <Icon type="folder-multiple" />
                </div>
            </td>
        </tr>
    );
};

const LoadingBar = () => {
    const { loading: loadingAccounts } = useAccounts();
    const { loading: loadingTaggings } = useTaggings();
    const { loading: loadingTags } = useTags();
    const { loading: loadingViewer } = useViewer();
    const loading = loadingAccounts || loadingTaggings || loadingTags || loadingViewer;

    return <ProgressIndeterminate visible={loading && !document.querySelector('.modal')} />;
};

export const DirectoriesPage = (props) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { current: currentAccount } = useAccounts();
    let {
        checkAllTaggings,
        checkedTaggings = [],
        clearTaggings = () => {},
        createFile,
        current: currentTagging = {},
        hasNextPage: hasNextTaggingsPage,
        listTaggings = () => {},
        loading: loadingTaggings,
        selectTagging,
        taggings = { edges: [] },
        uncheckAllTaggings,
    } = useTaggings();
    let {
        checkAllTags,
        clearTags = () => {},
        checkedTags = [],
        createTag,
        currentDir = {},
        current: currentTag = {},
        hasNextPage: hasNextTagsPage,
        hierarchical = [],
        listTags = () => {},
        loading: loadingTags,
        selectTag,
        tags = { edges: [] },
        uncheckAllTags,
    } = useTags();
    const [root, setRoot] = useState(true);
    const [isAnidated, setIsAnidated] = useState(!!currentTag.id);
    const [name, setName] = useState('');
    const [lastChecked, setLastChecked] = useState('');
    const { clipboard, store } = useClipboard();
    const { preferences = {}, setDirectoriesView } = useViewer();
    const { directories = {} } = preferences;
    const { view: directoriesView } = directories;
    const [view, setView] = useState(directoriesView);
    const { children: currentTags = [] } = currentDir;
    const currentTaggings = taggings.edges.filter(({ node: { tag } }) => tag.id === currentTag.id);
    const someChecked = !!checkedTags.length || !!checkedTaggings.length;
    const checked =
        someChecked &&
        currentTags.length === checkedTags.length &&
        currentTaggings.length === checkedTaggings.length;
    const showRootPlaceholder =
        root &&
        (!tags.edges.length ||
            (tags.edges.length === 1 &&
                !(
                    taggings.edges.filter(
                        ({
                            node: {
                                tag: { id },
                            },
                        }) => id === tags.edges[0].node.id
                    ) || []
                ).length));

    useEffect(() => {
        document.body.dataset.mobile = isMobile;
        listTags();
    }, []);

    useEffect(() => {
        setIsAnidated(!!currentTag.id && currentTag.name !== '/');
    }, [currentTag]);

    useEffect(() => {
        setRoot(currentTag.name === '/');
        clearTaggings();
        clearTags();
    }, [location]);

    useEffect(() => {
        if (directoriesView) {
            setView(directoriesView);
        }
    }, [preferences]);

    useEffect(() => {
        const { pathname } = location;
        const directoryPath = encodeURI(decodeURI(pathname));
        let directory = findDirectoryByPathname(hierarchical, directoryPath);
        directory = directory.id
            ? directory
            : {
                  ...(tags.edges.find(({ node }) => node.name === '/') || {}),
                  children: hierarchical,
              };
        const tag = tags.edges.find(({ node }) => node.id === directory.id) || {};
        const fileName = decodeURI(pathname.replace(`${tag.to}/`, ''));
        const { node: tagging } = taggings.edges.find(({ name }) => name === fileName) || {};
        const { node: tagNode = {} } = tag;
        if (tagNode.id) {
            uncheckAllTaggings();
            uncheckAllTags();
            selectTag(tagNode);
        }
        tagging && selectTagging(tagging);
        if (currentAccount && !currentTag.id) {
            createTag({ variables: { accountId: currentAccount.id, name: '/' } });
        }
    }, [tags.edges.length, location]);

    const createDirectory = async () => {
        let { parentTag } = clipboard;
        if (!parentTag) {
            const { data = {} } =
                (await createTag({ variables: { accountId: currentAccount.id, name: '/' } })) || {};
            parentTag = data.createTag;
        }

        let newTagName = `${
            parentTag && parentTag.name !== '/' ? `${parentTag.name}/` : ''
        }${name}`;
        newTagName = newTagName.replace(/[\\/]+$/, '').replace(/[\\/]+/g, '/');

        createTag({ variables: { accountId: currentAccount.id, name: newTagName } });
        window.$('.close').click();
    };

    const handleChangeDirectoryName = (event) => {
        const { value } = event.currentTarget;
        setName(value);
    };

    const handleKeyDownDirectoryName = (event) => {
        const { key } = event;
        if (key === 'Enter') {
            document.getElementById('CreateDirectoryButton').click();
        }
    };

    const handleChangeAnidated = (event) => {
        const anidated = event.currentTarget.checked;
        setIsAnidated(anidated);
        !anidated && store({ parentTag: { name: '/' } });
    };

    const handleClickAddFilesButton = (event) => {
        const input = document.getElementById('FileInput');
        const isDirectory = !!event.target.attributes.directory;

        if (isDirectory) {
            return;
        }

        input.value = '';
        input.click();
    };

    const handleClickAddDirectoryButton = (event) => {
        event.stopPropagation();
        const input = document.getElementById('DirectoryInput');
        input.value = '';
        input.click();
    };

    const handleInputClick = (event) => {
        const { currentTarget, shiftKey } = event;

        setLastChecked(currentTarget);

        if (!lastChecked || !shiftKey) {
            return;
        }

        const checkboxes = Object.values(
            document.querySelectorAll(
                '.tagging input[type="checkbox"], .tag input[type="checkbox"]'
            )
        );
        const start = checkboxes.indexOf(currentTarget);
        const end = checkboxes.indexOf(lastChecked);
        const checkboxesToCheck = checkboxes.slice(Math.min(start, end), Math.max(start, end) + 1);

        checkboxesToCheck.forEach((checkbox) => {
            if (checkbox === lastChecked) {
                return;
            }
            lastChecked.checked !== checkbox.checked && checkbox.click();
        });

        setLastChecked(currentTarget);
    };

    const handleChangeFiles = useCallback(
        async (event) => {
            const addedFiles = Object.values(event.currentTarget.files).filter(
                ({ name }) => !fileBlackList.includes(name)
            );

            if (!currentTag.id) {
                const {
                    data: { createTag: tag },
                } = await createTag({
                    variables: { accountId: currentAccount.id, name: '/' },
                });
                currentTag = tag;
            }

            addedFiles.forEach(async (file) => {
                let { webkitRelativePath } = file;
                if (webkitRelativePath) {
                    webkitRelativePath = webkitRelativePath.replace(/\/[^/]*$/, '');
                    webkitRelativePath = `${
                        currentTag && currentTag.name !== '/' ? `${currentTag.name}/` : ''
                    }${webkitRelativePath}`;

                    let parentTag = tags.edges.find(({ name }) => name === webkitRelativePath);
                    if (!parentTag) {
                        const {
                            data: { createTag: tag },
                        } = await createTag({
                            variables: { accountId: currentAccount.id, name: webkitRelativePath },
                        });
                        parentTag = tag;
                    }
                    createFile({ variables: { file, tagId: parentTag.id } });
                }
            });
        },
        [currentTag]
    );

    const handleCheckAll = useCallback(
        (event) => {
            const { checked } = event.target;
            if (!checked) {
                uncheckAllTaggings();
                uncheckAllTags();
            } else {
                checkAllTaggings(currentTag);
                checkAllTags(currentTag);
            }
        },
        [currentTag]
    );

    const externalRef = useRef();
    const { isNearScreen } = useNearScreen({
        externalRef: loadingTaggings || loadingTags ? null : externalRef,
        once: false,
    });
    const debounceHandleNextPage = useCallback(
        debounce(async () => {
            (hasNextTagsPage && (await listTags())) ||
                (hasNextTaggingsPage && (await listTaggings()));
        }, 200),
        [hasNextTaggingsPage, hasNextTagsPage, listTaggings, listTags]
    );

    useEffect(
        function() {
            if (isNearScreen) debounceHandleNextPage();
        },
        [debounceHandleNextPage, isNearScreen]
    );

    useEffect(() => {
        if (!loadingTags) {
            tags.edges.forEach(({ node: tag }, i) => {
                let { name } = tag;
                const subNames = name.split('/');
                const interval = setInterval(() => {
                    if (name && !tags.edges.find(({ node: tag }) => tag.name === name)) {
                        createTag({ variables: { accountId: currentAccount.id, name } });
                    }
                    subNames.pop();
                    name = subNames.join('/');
                    if (!subNames.length) {
                        clearInterval(interval);
                    }
                }, 0);
            });
        }
    }, [tags.edges]);

    return (
        <main className="directories">
            <LoadingBar />
            <Sidebar>
                <DirectoriesMenu active={currentDir} />
            </Sidebar>
            <div className="container-wrapper py-0">
                <div className="d-flex">
                    <SearchBox value={currentDir} className="flex-grow-1 mt-3 pb-3 bg-white" />
                </div>
                <div
                    id="DirectoriesWrapper"
                    className="border-top flex-grow-1 d-flex flex-column"
                    style={{ overflow: 'auto' }}
                    data-view={view}
                >
                    {isMobile && <Actions />}
                    <Table className="mb-0 text-nowrap" data-hide-on="mobile">
                        <thead>
                            <tr>
                                <th className="align-middle border-top-0">
                                    <Checkbox
                                        id="SelectAllFiles"
                                        aria-describedby="file selector"
                                        indeterminate={someChecked && !checked}
                                        onChange={handleCheckAll}
                                        checked={checked}
                                    />
                                </th>
                                <th colSpan={5} className="align-middle p-0 border-top-0">
                                    <Actions />
                                </th>
                            </tr>
                            <tr>
                                <th />
                                <th data-hide-on="grid,mobile">{t('common.name')}</th>
                                <th data-hide-on="grid,mobile">{t('common.extension')}</th>
                                <th data-hide-on="grid,mobile">{t('common.size')}</th>
                                <th data-hide-on="grid,mobile">{t('common.creation_date')}</th>
                                <th className="text-right">
                                    <UncontrolledDropdown direction="down">
                                        <DropdownToggle caret nav className="p-0">
                                            <Tooltip title={t('directories.view_as')}>
                                                <Icon type={`view-${view}`} />
                                            </Tooltip>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem header>
                                                {t('directories.view_as')}
                                            </DropdownItem>
                                            <DropdownItem
                                                className={`${view === 'grid' ? 'active' : ''}`}
                                                onClick={() => setDirectoriesView('grid')}
                                            >
                                                <Icon type="view-grid" className="mr-2" />
                                                <span>{t('common.grid')}</span>
                                            </DropdownItem>
                                            <DropdownItem
                                                className={`${view === 'list' ? 'active' : ''}`}
                                                onClick={() => setDirectoriesView('list')}
                                            >
                                                <Icon type="view-list" className="mr-2" />
                                                <span>{t('common.list')}</span>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </th>
                            </tr>
                        </thead>
                    </Table>
                    <div className="border-top flex-grow-1 overflow-auto item-list">
                        <Table className="mb-0 text-nowrap">
                            <tbody>
                                {(showRootPlaceholder && renderRootPlaceholder()) || (
                                    <>
                                        <Tags handleInputClick={handleInputClick} />
                                        <Taggings handleInputClick={handleInputClick} />
                                    </>
                                )}
                            </tbody>
                        </Table>
                        <div className="w-100" ref={externalRef} />
                    </div>
                </div>
                <UncontrolledDropdown
                    direction="down"
                    className="position-absolute-bottom-right-0 position-fixed m-4 z-index-2"
                >
                    <DropdownToggle nav className="p-0">
                        <Button
                            color="primary"
                            className="badge-pill p-0 font-size-26 border-0"
                            style={{ width: '50px' }}
                        >
                            <Icon type="plus" className="square" />
                        </Button>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem
                            onClick={() => document.getElementById('CreateFolderModal').click()}
                        >
                            {t('directories.create_folder')}
                        </DropdownItem>
                        <DropdownItem onClick={handleClickAddFilesButton}>
                            {t('directories.upload_files')}
                        </DropdownItem>
                        {!isMobile && (
                            <DropdownItem onClick={handleClickAddDirectoryButton}>
                                {t('directories.upload_directory')}
                            </DropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>
                <Input
                    accept="*"
                    className="d-none"
                    id="FileInput"
                    multiple
                    onChange={handleChangeFiles}
                    type="file"
                />
                <input
                    className="d-none"
                    directory="directory"
                    id="DirectoryInput"
                    multiple
                    onChange={handleChangeFiles}
                    tabIndex="-1"
                    type="file"
                    webkitdirectory="webkitdirectory"
                />
            </div>
            <CustomModal
                className="preview-modal"
                header={<Actions />}
                isOpen={!!currentTagging.id}
                buttonParameters={{
                    color: 'link',
                    className: 'badge-pill p-0 font-size-26 border-0 text-muted',
                    style: {
                        width: '40px',
                    },
                }}
                title={currentTagging.name}
                toggle={() => currentTagging.id && clearTaggings()}
            >
                <FilePreview {...currentTagging.file} name={currentTagging.name} />
            </CustomModal>
            <CustomModal
                button=""
                buttonParameters={{
                    className: 'd-none',
                    id: 'CreateFolderModal',
                }}
                onOpened={() => document.getElementById('NewDirectoryName').focus()}
                title={t('directories.create_folder')}
            >
                <Label className="h6 small d-block text-uppercase">{t('common.name')}</Label>
                <Input
                    id="NewDirectoryName"
                    data-cy="newDirectoryName"
                    onChange={handleChangeDirectoryName}
                    onKeyDown={handleKeyDownDirectoryName}
                />
                <FormGroup>
                    <div className="custom-control custom-checkbox mt-2">
                        <Input
                            id="AnidateDirectory"
                            type="checkbox"
                            className="custom-control-input"
                            aria-describedby="anidate directory"
                            onChange={handleChangeAnidated}
                            checked={isAnidated}
                        />{' '}
                        <label
                            htmlFor="AnidateDirectory"
                            data-cy="newDirectoryIsAnidated"
                            className="small custom-control-label mb-3 w-100 text-muted"
                        >
                            {t('directories.anidate')}
                        </label>
                    </div>
                    <Collapse isOpen={isAnidated}>
                        <DirectorySelect
                            tag={
                                currentTag.id && currentTag.label && currentTag.label !== '/'
                                    ? currentTag
                                    : ''
                            }
                        />
                    </Collapse>
                </FormGroup>
                <Button
                    id="CreateDirectoryButton"
                    className="mt-3"
                    disabled={!name}
                    data-cy="createDirectory"
                    onClick={createDirectory}
                >
                    {t('common.create')}
                </Button>
            </CustomModal>
        </main>
    );
};

export default DirectoriesPage;
