// @flow
import React, { useEffect, useState } from 'react';
import { Button, Modal as BootstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const CustomModal = ({ children, ...props }) => {
    let {
        button = false,
        buttonParameters = {},
        cancel,
        className,
        footer,
        header,
        id,
        isOpen: isOpenProp = false,
        onOpened = () => {},
        onClosed = () => {},
        size,
        style = {},
        title = '',
        toggle: toggleProp,
    } = props;
    children = Array.isArray(children) ? children : [children];

    const [isOpen, setIsOpen] = useState(isOpenProp);

    const toggle = toggleProp || (() => setIsOpen(!isOpen));

    const modalProps = { isOpen, toggle };
    id && (modalProps.id = id);
    size && (modalProps.size = size);
    className && (modalProps.className = className);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setIsOpen(isOpenProp);
        }
        return () => {
            mounted = false;
        };
    }, [isOpenProp]);

    return (
        <React.Fragment>
            {button !== false && (
                <Button {...buttonParameters} onClick={buttonParameters.onClick || toggle}>
                    {button}
                </Button>
            )}

            <BootstrapModal {...modalProps} onOpened={onOpened} onClosed={onClosed} style={style}>
                {(title || header) && (
                    <ModalHeader toggle={toggle}>
                        <span className="text-break">{title}</span>
                        {header}
                    </ModalHeader>
                )}
                <ModalBody>
                    {children.map((child) => child)}
                    {cancel && (
                        <Button onClick={this.toggle} className="cancel">
                            {cancel}
                        </Button>
                    )}
                </ModalBody>
                {footer && <ModalFooter>{footer}</ModalFooter>}
            </BootstrapModal>
        </React.Fragment>
    );
};

export default CustomModal;
