import gql from 'graphql-tag';

export const CREATE_TAGGING = gql`
    mutation CreateTagging($tagId: ID!, $fileId: ID!, $name: String) {
        createTagging(input: { tagId: $tagId, fileId: $fileId, name: $name }) {
            id
            name
            tag {
                id
                name
            }
            file {
                id
                hash
                extension
                mimeType
                size
                url
                createdAt
            }
            sharings(first: 1) {
                edges {
                    node {
                        id
                        token
                    }
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const UPDATE_TAGGING = gql`
    mutation UpdateTagging($taggingId: ID!, $name: String!) {
        updateTagging(input: { taggingId: $taggingId, name: $name }) {
            id
            name
            updatedAt
        }
    }
`;

export const DELETE_TAGGING = gql`
    mutation DeleteTagging($id: ID!) {
        deleteTagging(id: $id) {
            id
        }
    }
`;
