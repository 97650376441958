import React, { useEffect, useState } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { i18n, languages } from '../i18n';
import { useViewer } from '../hooks';

const getLanguageTextFromCode = (code) => {
    code = code.split('-')[0];
    return (languages.filter((lang) => lang.code === code)[0] || {}).text;
};

const Language = (props) => {
    const {
        toggleClassName = '',
        toggleColor = 'outline-secondary',
        className,
        direction,
        language = i18n.language,
        menu,
    } = props;
    const { setLanguage } = useViewer();
    const [currentLanguage, setCurrentLanguage] = useState({
        code: language,
        text: getLanguageTextFromCode(language),
    });

    useEffect(() => {
        i18n.on('languageChanged', initializeLanguage);
        return () => {
            i18n.off('languageChanged', initializeLanguage);
        };
    }, []);

    const initializeLanguage = (code) => {
        setCurrentLanguage({
            code,
            text: getLanguageTextFromCode(code),
        });
    };
    const handleLanguageChange = (event) => {
        const { onChange } = props;
        if (onChange) {
            onChange(event);
            return;
        }

        const opt = event.currentTarget;
        const language = opt.dataset.value;

        i18n.changeLanguage(language);
        setLanguage(language);
    };

    return (
        <UncontrolledDropdown direction={direction} className={className} data-cy="language">
            <DropdownToggle caret color={toggleColor} className={toggleClassName}>
                {currentLanguage.text}
            </DropdownToggle>
            <DropdownMenu nav="true" {...menu}>
                {languages.map((lang) => (
                    <DropdownItem
                        key={lang.code}
                        active={currentLanguage.code === lang.code}
                        data-value={lang.code}
                        onClick={handleLanguageChange}
                    >
                        {lang.text}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default Language;
export { Language };
