// @flow
import React from 'react';
import { Col, NavItem, Row } from 'reactstrap';
import { PlaceholderImage, PlaceholderText, SidebarMenu, Tree } from '../../components';
import { useTags } from '../../hooks';

export const DirectoriesMenu = ({ active }) => {
    const { hierarchical = [], tags = { edges: [] }, update } = useTags();

    const renderPlaceholder = () => {
        return (
            <div>
                <Row className="mx-0 mt-2 mb-3 align-items-center">
                    <Col className="p-0 flex-grow-0">
                        <PlaceholderImage
                            color="dark"
                            className="ml-2 mr-3"
                            style={{ width: '18px', height: '14px' }}
                        />
                    </Col>
                    <Col className="p-0">
                        <PlaceholderText style={{ width: '130px' }} />
                    </Col>
                </Row>
                <Row className="mx-0 my-3 align-items-center">
                    <Col className="p-0 flex-grow-0">
                        <PlaceholderImage
                            color="dark"
                            className="ml-2 mr-3"
                            style={{ width: '18px', height: '14px' }}
                        />
                    </Col>
                    <Col className="p-0">
                        <PlaceholderText style={{ width: '150px' }} />
                    </Col>
                </Row>
                <Row className="mx-0 my-3 align-items-center">
                    <Col className="p-0 flex-grow-0">
                        <PlaceholderImage
                            color="dark"
                            className="ml-2 mr-3"
                            style={{ width: '18px', height: '14px' }}
                        />
                    </Col>
                    <Col className="p-0">
                        <PlaceholderText style={{ width: '140px' }} />
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <SidebarMenu active={active}>
            <NavItem />
            <NavItem
                className="flex-grow-1 mx--3 px-3 pb-3 position-relative"
                style={{ overflow: 'auto' }}
            >
                {(!update && !tags.edges.length && renderPlaceholder()) || (
                    <Tree data={hierarchical} droppable={true} />
                )}
            </NavItem>
        </SidebarMenu>
    );
};

export default DirectoriesMenu;
