// @flow
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    Navbar,
    NavItem,
    UncontrolledCollapse,
    UncontrolledDropdown,
} from 'reactstrap';
import { PAGES, URL } from '../_config';
import { getAvatar } from '../_helpers';
import { Icon } from '.';
import { useTags, useViewer } from '../hooks';
import { logoMinCodoozer, logoMinWhite, logoWhite } from '../assets/svg/logos';

const toggleBox = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const target = event.currentTarget;
    document.querySelector(`.${target.dataset.toggle}`).parentElement.classList.toggle('show');
};

const CodoozerItem = () => {
    const { tags = { edges: [] } } = useTags();
    const hasCodoozerTag = tags.edges.some(({ node }) => node.name === 'codoozer');

    if (!hasCodoozerTag) {
        return null;
    }

    return (
        <NavItem className="flex-grow-1 mt-1">
            <Link to={`${PAGES.TAGS}/codoozer`} className="d-block text-center mt-2">
                <Button className="icon border-0 rounded-circle">
                    <Tooltip placement="right" title="Codoozer">
                        <img src={logoMinCodoozer} width="24px" />
                    </Tooltip>
                </Button>
            </Link>
        </NavItem>
    );
};

const Sidebar = ({ children }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const { logout, viewer = {} } = useViewer();
    const { accounts: { edges: accounts = [{ node: {} }] } = {} } = viewer;
    let account = accounts[0].node;
    let { avatar: hash } = account;

    return (
        <>
            <Navbar
                color="primary"
                light
                expand="md"
                className={`sidebar-wrapper${
                    children ? ' sidebar-wide' : ''
                } p-2 p-md-0 justify-content-start`}
            >
                <UncontrolledCollapse navbar toggler="#SidebarToggler">
                    <nav className="sidebar sidebar-main bg-secondary">
                        <Nav vertical className="justify-content-between align-items-center">
                            <Link to={PAGES.TAGS} data-cy="logo" className="my-4 p-0">
                                <img alt="logo Kustodio" src={logoMinWhite} height="42px" />
                            </Link>

                            <NavItem className="mt-1">
                                <Link to={PAGES.TAGS} className="d-block text-center mt-2">
                                    <Button
                                        className={`icon border-0 rounded-circle ${
                                            location.pathname.includes(PAGES.TAGS)
                                                ? 'text-primary opacity-1'
                                                : ''
                                        }`}
                                    >
                                        <Tooltip placement="right" title={t('common.my_files')}>
                                            <Icon type="file-multiple" />
                                        </Tooltip>
                                    </Button>
                                </Link>
                            </NavItem>

                            <CodoozerItem />

                            <NavItem className="flex-grow-1" />

                            <UncontrolledDropdown nav direction="right" className="mb-2">
                                <DropdownToggle
                                    color="primary"
                                    className="icon border-0 bg-white rounded-circle mb-3"
                                >
                                    <img
                                        width="36"
                                        alt="avatar"
                                        src={getAvatar({ hash })}
                                        className="rounded-circle"
                                    />
                                </DropdownToggle>
                                {(accounts.length && (
                                    <DropdownMenu right>
                                        <DropdownItem header>
                                            {t('common.account', {
                                                count: accounts.length,
                                            })}
                                        </DropdownItem>
                                        {accounts.map(({ node }, i) => {
                                            const {
                                                id,
                                                identifier,
                                                identifiers: { edges: identifiers = [] } = {},
                                                name,
                                                type,
                                            } = node;
                                            const { subject } =
                                                identifiers
                                                    .map(({ node }) => node)
                                                    .filter(({ default: d }) => d)[0] || {};
                                            const nameToShow = name || identifier || subject;
                                            return (
                                                <DropdownItem
                                                    key={`Account-${i}`}
                                                    className={
                                                        id === node.id
                                                            ? 'font-weight-bold text-primary disabled opacity-1'
                                                            : ''
                                                    }
                                                    data-cy={`${type}-account`}
                                                    onClick={() => (account = node)}
                                                >
                                                    {nameToShow}
                                                </DropdownItem>
                                            );
                                        })}
                                        <DropdownItem divider />
                                        <a
                                            href={`${URL.DOYO}${PAGES.ACCOUNT}`}
                                            target="_blank"
                                            rel="Doyo account noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.view_profile')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.SUBSCRIPTION}`}
                                            target="_blank"
                                            rel="Doyo subscription noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.subscription')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.BILLING_PAYMENT}`}
                                            target="_blank"
                                            rel="Doyo billing payment noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.payment_methods')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.BILLING_DATA}`}
                                            target="_blank"
                                            rel="Doyo billing data noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.billing_data')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <a
                                            href={`${URL.DOYO}${PAGES.BILLING_HISTORY}`}
                                            target="_blank"
                                            rel="Doyo billing history noopener noreferrer"
                                        >
                                            <DropdownItem className="d-flex">
                                                <span className="flex-grow-1">
                                                    {t('header.receipts')}
                                                </span>
                                                <Icon
                                                    type="open-in-new"
                                                    className="ml-3 text-gray"
                                                />
                                            </DropdownItem>
                                        </a>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => logout()}>
                                            {t('common.sign_out')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                )) ||
                                    ''}
                            </UncontrolledDropdown>
                        </Nav>
                    </nav>
                    {children}
                </UncontrolledCollapse>
                <div id="SidebarToggler" className="d-none" />
                <Button
                    color="link"
                    data-toggle="sidebar-main"
                    onClick={toggleBox}
                    className="sidebar-toggler icon border-0 rounded-circle d-md-none text-white"
                    data-cy="sidebar-toggler"
                >
                    <Icon type="menu" />
                </Button>
                <img alt="logo Kustodio" src={logoWhite} className="d-md-none" height="32px" />
            </Navbar>
        </>
    );
};

export default Sidebar;
export { Sidebar };
