// @flow
import React from 'react';

type PropsT = {
    className: string,
    color: string,
    size: string,
};

export const PlaceholderImage = (props: PropsT) => {
    const { className, color, size } = props;
    const classNameString = `placeholder placeholder-image${size ? ` ${size}-placeholder` : ''}${
        color ? ` bg-${color}` : ''
    }${className ? ` ${className}` : ''}`;
    return <div {...props} className={classNameString} />;
};

export const PlaceholderText = (props: PropsT) => {
    const { className, color, size } = props;
    const classNameString = `placeholder placeholder-text${size ? ` ${size}-placeholder` : ''}${
        color ? ` bg-${color}` : ''
    }${className ? ` ${className}` : ''}`;
    return <span {...props} className={classNameString} />;
};
