import gql from 'graphql-tag';

export const CREATE_FILE = gql`
    mutation CreateFile($tagId: ID!, $file: Upload!) {
        createFile(input: { tagId: $tagId, file: $file }) {
            id
            name
            tag {
                id
                name
            }
            file {
                id
                hash
                extension
                mimeType
                size
                url
                downloadUrl
                createdAt
            }
            sharings(first: 1) {
                edges {
                    node {
                        id
                        token
                    }
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const REPLACE_TAGGING_FILE = gql`
    mutation ReplaceTaggingFile($taggingId: ID!, $file: Upload!) {
        replaceTaggingFile(input: { taggingId: $taggingId, file: $file }) {
            id
            file {
                id
                url
            }
        }
    }
`;

export const DELETE_TAGGING_FILE = gql`
    mutation DeleteIdentifier($id: ID!) {
        deleteIdentifier(id: $id) {
            id
        }
    }
`;
