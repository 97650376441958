// @flow
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, Navbar, NavbarToggler, Nav, NavItem } from 'reactstrap';
import { PAGES, URL } from '../_config';
import { logo } from '../assets/svg/logos';

export const Header = () => {
    const { t } = useTranslation();
    const [collapse, setCollapse] = useState();

    const toggle = () => {
        setCollapse(!collapse);
    };

    return (
        <header className="fixed-top">
            <Navbar color="light" light expand="sm">
                <Link to="/" className="navbar-brand">
                    <img alt="logo Kustodio" src={logo} height="42px" />
                </Link>
                <NavbarToggler onClick={toggle} />
                <Collapse isOpen={collapse} navbar>
                    <Nav navbar>
                        <NavItem>
                            <a
                                href={`${URL.DOYO}${PAGES.SIGN_IN}/${URL.FACE}`}
                                className="nav-link"
                            >
                                <Button
                                    color="primary"
                                    outline
                                    block
                                    className="text-uppercase font-weight-bold"
                                >
                                    {t('common.sign_in')}
                                </Button>
                            </a>
                        </NavItem>
                        <NavItem>
                            <a
                                href={`${URL.DOYO}${PAGES.SIGN_UP}/${URL.FACE}`}
                                className="nav-link"
                            >
                                <Button
                                    color="primary"
                                    block
                                    className="text-uppercase font-weight-bold"
                                >
                                    {t('common.sign_up_long')}
                                </Button>
                            </a>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        </header>
    );
};

export default Header;
