import React from 'react';
import { Checkbox } from 'antd';
import { Button } from 'reactstrap';
import { FilePreview, Icon, PlaceholderImage, PlaceholderText } from '../../components';
import { getTaggingIcon, humanFileSize } from '../../_helpers';
import { useTaggings, useTags, useViewer } from '../../hooks';

export const Taggings = ({ handleInputClick = () => {} }) => {
    const {
        checkTaggings,
        current: currentTagging = {},
        loading,
        selectTagging,
        taggings = { edges: [] },
        uncheckTaggings,
    } = useTaggings();
    const { current: currentTag = {}, tags = { edges: [] } } = useTags();
    const { preferences } = useViewer();
    const { directories = {} } = preferences;
    const { view } = directories;

    const someChecked =
        tags.edges.some(({ checked }) => checked) || taggings.edges.some(({ checked }) => checked);

    const handleTaggingCheck = (event, tagging) => {
        const { checked } = event.target;
        if (checked) {
            checkTaggings([tagging]);
        } else {
            uncheckTaggings([tagging]);
        }
    };

    const dragStart = (event, tagging) => {
        event.dataTransfer.effectAllowed = 'move';
        event.dataTransfer.dropEffect = 'move';
        event.dataTransfer.setData('text/plain', JSON.stringify({ tagging }));
    };

    const renderPlaceholder = () => {
        return (
            <React.Fragment>
                <tr>
                    <td className="align-middle">
                        <PlaceholderImage
                            color="gray"
                            className="mr-2"
                            style={{ width: '16px', height: '16px' }}
                        />
                    </td>
                    <td className="py-4 align-middle">
                        <div className="d-flex">
                            <PlaceholderImage
                                color="gray"
                                className="mr-2"
                                style={{ width: '30px' }}
                            />
                            <PlaceholderText style={{ width: '130px' }} />
                        </div>
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '50px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '60px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '120px' }} />
                    </td>
                    <td />
                </tr>
                <tr>
                    <td className="align-middle">
                        <PlaceholderImage
                            color="gray"
                            className="mr-2"
                            style={{ width: '16px', height: '16px' }}
                        />
                    </td>
                    <td className="py-4 align-middle">
                        <div className="d-flex">
                            <PlaceholderImage
                                color="gray"
                                className="mr-2"
                                style={{ width: '30px' }}
                            />
                            <PlaceholderText style={{ width: '200px' }} />
                        </div>
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '50px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '60px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '120px' }} />
                    </td>
                    <td />
                </tr>
                <tr>
                    <td className="align-middle">
                        <PlaceholderImage
                            color="gray"
                            className="mr-2"
                            style={{ width: '16px', height: '16px' }}
                        />
                    </td>
                    <td className="py-4 align-middle">
                        <div className="d-flex">
                            <PlaceholderImage
                                color="gray"
                                className="mr-2"
                                style={{ width: '30px' }}
                            />
                            <PlaceholderText style={{ width: '160px' }} />
                        </div>
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '50px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '60px' }} />
                    </td>
                    <td className="align-middle" data-hide-on="grid,mobile">
                        <PlaceholderText style={{ width: '120px' }} />
                    </td>
                    <td />
                </tr>
            </React.Fragment>
        );
    };

    return (
        <>
            {taggings.edges
                .filter(({ node: { tag } }) => tag.id === currentTag.id)
                .map((tagging, i) => {
                    const { checked = false, cuted = false, node } = tagging;
                    const { createdAt, file, id, name } = node;
                    const { extension, size } = file;
                    const creationDate = new Date(createdAt).toLocaleString();

                    return (
                        <tr
                            key={`Tagging-${id}`}
                            className={`tagging${currentTagging.id === id ? ' active' : ''}`}
                            data-checked={checked}
                            data-some-checked={someChecked}
                        >
                            <td className="align-middle">
                                <Checkbox
                                    id={`TaggingInput-${id}`}
                                    onChange={(event) => handleTaggingCheck(event, tagging)}
                                    onClick={handleInputClick}
                                    checked={checked}
                                />
                            </td>
                            <td className="align-middle">
                                <div
                                    className={`d-flex align-items-center${
                                        cuted ? ' opacity-0_2' : ''
                                    }`}
                                    draggable={true}
                                    onDragStart={(event) => dragStart(event, tagging)}
                                >
                                    <Button
                                        block
                                        className="shadow-none"
                                        color="link"
                                        onClick={() =>
                                            someChecked
                                                ? document
                                                      .getElementById(`TaggingInput-${id}`)
                                                      .click()
                                                : selectTagging(node)
                                        }
                                    >
                                        {view === 'grid' && (
                                            <FilePreview {...node.file} name={node.name} />
                                        )}
                                        <Icon
                                            type={getTaggingIcon(node)}
                                            className="position-relative font-size-26 mr-2 text-black-50"
                                            data-hide-on="grid"
                                        />
                                        <span>{name}</span>
                                    </Button>
                                </div>
                            </td>
                            <td
                                className="position-relative align-middle"
                                data-hide-on="grid,mobile"
                            >
                                <small className="text-muted">{extension.toUpperCase()}</small>
                            </td>
                            <td
                                className="position-relative align-middle"
                                data-hide-on="grid,mobile"
                            >
                                <small className="text-muted">{humanFileSize(size)}</small>
                            </td>
                            <td
                                className="position-relative align-middle"
                                data-hide-on="grid,mobile"
                            >
                                <small className="text-muted">{creationDate}</small>
                            </td>
                            <td className="align-middle" data-hide-on="grid,mobile">
                                <Button
                                    color="link"
                                    className="preview-button"
                                    onClick={() => selectTagging(node)}
                                    style={{
                                        opacity: someChecked ? 1 : 0,
                                    }}
                                >
                                    <Icon type="eye" />
                                </Button>
                            </td>
                        </tr>
                    );
                })}
            {(loading && !taggings.edges.length && renderPlaceholder()) || null}
        </>
    );
};

export default Taggings;
