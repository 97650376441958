import gql from 'graphql-tag';

export const LIST_TAGGINGS = gql`
    query ListTaggings($id: ID!, $cursor: String, $first: Int!) {
        tag(id: $id) {
            taggings(first: $first, after: $cursor) {
                edges {
                    node {
                        id
                        name
                        tag {
                            id
                        }
                        file {
                            id
                            hash
                            extension
                            mimeType
                            size
                            url
                            createdAt
                        }
                        sharings(first: $first) {
                            edges {
                                node {
                                    id
                                    token
                                }
                            }
                        }
                        createdAt
                        updatedAt
                    }
                }
                pageInfo {
                    endCursor
                    hasNextPage
                }
            }
        }
    }
`;

export const SHOW_TAGGING = gql`
    query ShowTagging($id: ID!) {
        tagging(id: $id) {
            id
            name
            tag {
                id
                name
            }
            file {
                id
                hash
                extension
                mimeType
                size
                url
                createdAt
            }
            sharings(first: 1) {
                edges {
                    node {
                        id
                        token
                    }
                }
            }
            createdAt
            updatedAt
        }
    }
`;

export const SHOW_TAGGING_WITH_URL = gql`
    query ShowTagging($id: ID!) {
        tagging(id: $id) {
            id
            file {
                url
                downloadUrl
            }
        }
    }
`;
