import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { message } from 'antd';
import { Button } from 'reactstrap';
import { fileBlackList, getUniqueName } from '../_helpers';
import { useAccounts, useTaggings, useTags } from '../hooks';
import { CustomModal, FilePreview } from '.';

const DropWrapper = ({ className = '', mainFolder = '' }) => {
    const dragging = useRef([]);
    const { t } = useTranslation();
    const { current: currentAccount = {} } = useAccounts();
    const { createFile, replaceTaggingFile, taggings = { edges: [] } } = useTaggings();
    const { createTag, current: currentTag = {} } = useTags();
    const [askingToOverwrite, setAskingToOverwrite] = useState(false);
    const dropWrapper = useRef();
    const filesName = taggings.edges
        .filter(({ node: { tag } }) => tag.id === currentTag.id)
        .map(({ node: { name } }) => name);

    const handleDragEnter = (event) => {
        const { items } = event.dataTransfer;
        if (!items.length || Object.values(items).some(({ kind }) => kind !== 'file')) {
            return;
        }
        event.preventDefault();
        const { effectAllowed } = event.dataTransfer;
        const isShown = effectAllowed === 'all';
        const draggingFiles = isShown ? Object.values(items) : [];
        dragging.current = draggingFiles;
        dropWrapper.current.classList.add('show');
        message.open({
            className: 'position-absolute-top-0 text-center',
            content: parse(
                t('dropwrapper.directory', {
                    count: dragging.current.length,
                    target: currentTag.name || 'Kustodio',
                })
            ),
            duration: 0,
            key: 'DropMessage',
        });
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        dragging.current = [];
        dropWrapper.current.classList.remove('show');
        message.destroy('DropMessage');
    };

    const askToOverwrite = (filesToOverwrite) =>
        new Promise((resolve, reject) => {
            setAskingToOverwrite(filesToOverwrite);
            setTimeout(() => {
                const acceptButton = document.getElementById('OverwriteAccept');
                acceptButton &&
                    (acceptButton.onclick = () => {
                        setAskingToOverwrite(false);
                        resolve();
                    });
                const cancelButton = document.querySelector('.modal .overwrite-modal .close');
                cancelButton &&
                    (cancelButton.onclick = () => {
                        reject();
                    });
            }, 500);
        });

    const uploadDirectory = async (dir) => {
        const { children = [], name: tagName } = dir;
        const parentTagName = currentTag.name === '/' ? '' : `${currentTag.name}/`;
        const name = `${parentTagName}${tagName}`;
        const {
            data: { createTag: tag },
        } = await createTag({ variables: { accountId: currentAccount.id, name } });
        const filesToUpload = [];

        children.forEach((child) => {
            if (child.children) {
                uploadDirectory(child);
                return;
            }
            filesToUpload.push(
                new File([child], `${tag.name}/${child.name}`, {
                    ...child,
                })
            );
        });

        uploadFiles(filesToUpload, tag);
    };

    const uploadDirectories = (directories) => {
        directories.forEach((dir) => uploadDirectory(dir));
    };

    const uploadFiles = useCallback(
        async (files, tag = currentTag) => {
            const filesToUpload = Object.values(files).map((file) => {
                let { name } = file;
                return { file, name };
            });
            const newFilesName = filesToUpload.map(({ name }) => name);
            const filesToOverwrite = {
                from: taggings.edges.filter(({ node: { name } }) => newFilesName.includes(name)),
                to: filesToUpload.filter(({ name }) => filesName.includes(name)),
            };
            if (filesToOverwrite.from.length) {
                try {
                    await askToOverwrite(filesToOverwrite);
                } catch (e) {
                    return;
                }
            }
            await Promise.all(
                filesToUpload.map(async ({ file, name }, i) => {
                    const { overwrite } = file;
                    if (overwrite === true) {
                        replaceTaggingFile({
                            variables: { file, taggingId: filesToOverwrite.from[i].node.id },
                        });
                    } else {
                        file = new File([file], getUniqueName(name, { names: filesName }), {
                            ...file,
                        });
                        createFile({ variables: { file, tagId: tag.id } });
                    }
                })
            );
        },
        [currentTag]
    );

    const getDirectory = (dataTransferItem) => {
        if (!dataTransferItem) {
            return [];
        }
        const traverseFileTreePromise = (item, path = '') => {
            return new Promise((resolve) => {
                const { fullPath, isDirectory, isFile } = item;
                if (isFile) {
                    item.file((file) => {
                        file.filepath = path + file.name; //save full path
                        resolve(file);
                    });
                } else if (isDirectory) {
                    let dirReader = item.createReader();
                    dirReader.readEntries((entries) => {
                        let entriesPromises = Object.values(entries).map((entry) =>
                            traverseFileTreePromise(entry)
                        );
                        Promise.all(entriesPromises).then((children = []) => {
                            children = children.filter(({ name }) => !fileBlackList.includes(name));
                            resolve({ name: `${fullPath.substr(1)}`, children });
                        });
                    });
                }
            });
        };

        const item = dataTransferItem.webkitGetAsEntry();
        if (!item.isDirectory) {
            return false;
        }
        let dirReader = item.createReader();
        return new Promise((resolve) => {
            dirReader.readEntries((entries) => {
                let entriesPromises = Object.values(entries).map((entry) =>
                    traverseFileTreePromise(entry)
                );
                Promise.all(entriesPromises).then((children = []) => {
                    children = children.filter(({ name }) => !fileBlackList.includes(name));
                    resolve({ name: item.name, children });
                });
            });
        });
    };

    const handleDrop = async (event) => {
        event.preventDefault();
        dropWrapper.current.classList.remove('show');
        const { dataTransfer } = event;
        let { files, items } = dataTransfer;
        message.destroy('DropMessage');

        const filesToUpload = await Promise.all(
            Object.values(items).map(async (item, i) => {
                return (await getDirectory(item)) || new Promise((resolve) => resolve(files[i]));
            })
        );

        uploadDirectories(filesToUpload.filter(({ children }) => children));
        uploadFiles(filesToUpload.filter(({ children }) => !children));
    };

    useEffect(() => {
        document.addEventListener('dragenter', handleDragEnter, false);
        document.addEventListener('dragover', handleDragEnter, false);
        dropWrapper.current.addEventListener('dragleave', handleDragLeave, false);
        dropWrapper.current.addEventListener('drop', handleDrop, false);
        return () => {
            document.removeEventListener('dragenter', handleDragEnter);
            document.removeEventListener('dragover', handleDragEnter);
            dropWrapper.current.removeEventListener('dragleave', handleDragLeave);
            dropWrapper.current.removeEventListener('drop', handleDrop);
        };
    }, [currentTag, taggings.edges.length]);

    return (
        <>
            <div className={`drop-wrapper${className ? ` ${className}` : ''}`} ref={dropWrapper}>
                <div className="bg" />
            </div>
            <CustomModal
                button=""
                buttonParameters={{
                    id: 'AskToOverwriteModal',
                    className: 'd-none',
                }}
                className="overwrite-modal"
                footer={<Button id="OverwriteAccept">{t('common.accept')}</Button>}
                isOpen={!!askingToOverwrite}
                onClosed={() => setAskingToOverwrite(false)}
                title={t('dropwrapper.overwrite_modal_title')}
            >
                {(askingToOverwrite &&
                    askingToOverwrite.from.map(({ node: from }) => {
                        const { file, name } = askingToOverwrite.to.find(
                            ({ name }) => name === from.name
                        );
                        file.overwrite = true;
                        const to = {
                            name,
                            blob: file,
                            blobUrl: URL.createObjectURL(file),
                        };

                        return (
                            <div
                                className="overwrite-preview"
                                key={`Preview-${from.id}-${Date.now()}`}
                                data-overwrite={true}
                            >
                                <div>
                                    <div className="old-file">
                                        <FilePreview {...from} />
                                        <span>{name}</span>
                                    </div>
                                    <div className="new-file">
                                        <FilePreview {...to} />
                                        <span className="overwrite-name">{to.name}</span>
                                        <span className="keep-both-name">
                                            {getUniqueName(to.name, { names: filesName })}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <input
                                        id={`${to.name}KeepBoth`}
                                        type="checkbox"
                                        className="json-node-value-helper"
                                        onChange={(event) => {
                                            const { currentTarget } = event;
                                            const { checked } = currentTarget;
                                            event.currentTarget.parentNode.parentNode.dataset.overwrite = !checked;
                                            file.overwrite = !checked;
                                        }}
                                    />
                                    <label htmlFor={`${to.name}KeepBoth`}>
                                        {t('common.keep_both')}
                                    </label>
                                </div>
                            </div>
                        );
                    })) ||
                    null}
            </CustomModal>
        </>
    );
};

export default DropWrapper;
export { DropWrapper };
