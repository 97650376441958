import gql from 'graphql-tag';

export const LIST_SHARINGS = gql`
    query ListSharings($id: ID!, $first: Int!) {
        tagging(id: $id) {
            sharings(first: $first) {
                edges {
                    node {
                        id
                    }
                }
            }
        }
    }
`;
