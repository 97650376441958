import React from 'react';

export const Youtube = (props) => {
    const { apiKey, id, videoId } = props;

    return (
        <div>
            <iframe
                title={`Youtube${id || Date.now()}`}
                src={`https://www.youtube.com/embed/${videoId}`}
            />
        </div>
    );
};
