import React, { createContext, useCallback } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useImmerReducer } from 'use-immer';
import { message } from 'antd';
import { findInTree, getUniqueName } from '../_helpers';
import { useAccounts } from '../hooks';
import { CREATE_TAG, DELETE_TAG, UPDATE_TAG } from '../graphql/mutations';
import { LIST_TAGS, SHOW_TAG } from '../graphql/queries';
import { TAGS, TagsReducer } from './reducers';

export const TAG_TYPE = {
    LABEL: 'label',
    HIERARCHICAL: 'hierarchical',
    INTERNAL: 'internal',
};

export const TagsContext = createContext({});

let createTagging = () => {};
let newTag;
export const TagsProvider = (props) => {
    const [state, dispatch] = useImmerReducer(TagsReducer, TAGS.INITIAL_STATE);
    const { current: currentAccount = {} } = useAccounts();
    const { hierarchical = [], pageInfo = {}, tags } = state;
    const { endCursor: cursor, hasNextPage } = pageInfo;
    const { id: accountId } = currentAccount;

    const checkAllTags = (tag) => {
        dispatch({ type: TAGS.CHECK_ALL, payload: { tag } });
    };

    const checkTags = (tags) => {
        dispatch({ type: TAGS.CHECK, payload: { tags } });
    };

    const clearTags = () => {
        dispatch({ type: TAGS.CLEAR });
    };

    const copyTags = (tags) => {
        dispatch({ type: TAGS.COPY, payload: { tags } });
    };

    const [createTag] = useMutation(CREATE_TAG, {
        onCompleted: ({ createTag }) => {
            dispatch({ type: TAGS.CREATE_TAG, payload: { createTag } });
        },
    });

    const cutTags = (tags) => {
        dispatch({ type: TAGS.CUT, payload: { tags } });
    };

    const [deleteTag, { loading: loadingDeleteTag }] = useMutation(DELETE_TAG, {
        onCompleted: ({ deleteTag }) => {
            dispatch({ type: TAGS.DELETE_TAG, payload: { deleteTag } });
        },
    });

    let [listTags, { loading, fetchMore }] = useLazyQuery(LIST_TAGS, {
        variables: { accountId, first: 10 },
        onCompleted: ({ tags }) => {
            dispatch({
                type: TAGS.LIST_TAGS,
                payload: { tags },
            });
        },
    });

    const moveTags = (tag, options = {}) => {
        const { name, targetTag } = options;
        return new Promise((resolve) => {
            const { title } = tag;
            let { name: targetName } = targetTag;

            if (`/${tag.name}`.replace(title, '') === targetName) {
                dispatch({ type: TAGS.MOVE, payload: { tag } });
                resolve();
                return;
            }

            updateTag(tag, { name }).then(() => {
                dispatch({ type: TAGS.MOVE, payload: { tag } });
                resolve();
            });
        });
    };

    const pasteTags = useCallback(
        (tags, { tag, createTagging: _createTagging }) => {
            createTagging = _createTagging;
            const names = state.tags.edges
                .filter(({ node: { name } }) => {
                    const regExp = new RegExp(`^${tag.name}.+`);
                    return regExp.test(name);
                })
                .map(({ node: { name } }) => name);
            const copiedTags = tags.filter(({ copied }) => copied);
            const cutedTags = tags.filter(({ cuted }) => cuted);

            copiedTags.length &&
                copiedTags.forEach(async ({ node: { id, title } }) => {
                    const {
                        data: { createTag: _createTag },
                    } = await createTag({
                        variables: {
                            accountId: currentAccount.id,
                            name: getUniqueName(`${tag.name}/${title}`.replace('//', ''), {
                                names,
                            }),
                        },
                    });
                    newTag = _createTag;

                    showTag({ variables: { id, first: 100 } });
                });

            cutedTags.length && moveTags(cutedTags, { tag });
        },
        [tags.edges]
    );

    const selectTag = (tag) => {
        dispatch({ type: TAGS.SELECT, payload: { tag } });
    };

    const [showTag, { loading: loadingTaggingsFromTag }] = useLazyQuery(SHOW_TAG, {
        onCompleted: useCallback(
            ({ tag }) => {
                const { taggings } = tag;

                taggings.edges.length &&
                    taggings.edges.forEach(({ node: { file, name } }) =>
                        createTagging({
                            variables: {
                                fileId: file.id,
                                name,
                                tagId: newTag.id,
                            },
                        })
                    );

                const directory = findInTree(tag, hierarchical);
                const { children = [] } = directory;
                const childrenTags = children.map((dir) =>
                    state.tags.edges.find(({ node: { id } }) => id === dir.id)
                );
                children.length && pasteTags(childrenTags, { tag: newTag, createTagging });
            },
            [hierarchical]
        ),
    });

    const uncheckAllTags = () => {
        dispatch({ type: TAGS.UNCHECK_ALL });
    };

    const uncheckTags = (tags) => {
        dispatch({ type: TAGS.UNCHECK, payload: { tags } });
    };

    const [updateTag, { loading: loadingUpdateTag }] = useMutation(UPDATE_TAG, {
        onCompleted: ({ updateTag }) => {
            dispatch({ type: TAGS.UPDATE_TAG, payload: { updateTag } });
        },
    });

    const listMoreTags = useCallback(
        () =>
            fetchMore &&
            fetchMore({
                variables: { cursor },
                updateQuery: (prev, { fetchMoreResult }) => {
                    let { tags } = fetchMoreResult;

                    dispatch({
                        type: TAGS.LIST_TAGS,
                        payload: { tags },
                    });
                    return tags.edges.length
                        ? {
                              ...prev,
                              tags: {
                                  ...prev.tags,
                                  ...tags,
                                  edges: [...prev.tags.edges, ...tags.edges],
                              },
                          }
                        : prev;
                },
            }),
        [cursor]
    );

    loading = loading || loadingDeleteTag || loadingTaggingsFromTag || loadingUpdateTag;

    if (process.env.NODE_ENV === 'development') {
        console.log('TAGS >>>', state);
    }

    return (
        <TagsContext.Provider
            value={{
                ...state,
                checkAllTags,
                checkTags,
                clearTags,
                copyTags,
                createTag,
                cutTags,
                deleteTag,
                hasNextPage,
                loading,
                listTags: fetchMore ? listMoreTags : listTags,
                moveTags,
                pasteTags,
                selectTag,
                uncheckAllTags,
                uncheckTags,
                updateTag,
            }}
        >
            {props.children}
        </TagsContext.Provider>
    );
};

export const TagsConsumer = TagsContext.Consumer;
export default TagsContext;
