import React, { createContext, useEffect } from 'react';
import { useImmerReducer } from 'use-immer';
import { useQuery } from '@apollo/react-hooks';
import { IdentifierReducer, IDENTIFIER } from './reducers';
import { useAccounts } from '../hooks';
import { LIST_ACCOUNT_IDENTIFIERS } from '../graphql/queries';

export const IDENTIFIER_TYPE = {
    ALIAS: 'ALIAS',
    DOMAIN: 'DOMAIN',
    EMAIL: 'EMAIL',
    PHONE: 'PHONE',
};

export const VERIFICABLE_IDENTIFIER_TYPE = [
    IDENTIFIER_TYPE.DOMAIN,
    IDENTIFIER_TYPE.EMAIL,
    IDENTIFIER_TYPE.PHONE,
];

export const IdentifierContext = createContext(IDENTIFIER.INITIAL_STATE);

export const IdentifierProvider = ({ children }) => {
    const { current: currentAccount = {} } = useAccounts();
    const { id: accountId } = currentAccount;
    const [state, dispatch] = useImmerReducer(IdentifierReducer, IDENTIFIER.INITIAL_STATE);
    const { pageInfo = {} } = state;
    const { endCursor: cursor, hasNextPage } = pageInfo;

    let { fetchMore, loading, refetch } = useQuery(LIST_ACCOUNT_IDENTIFIERS, {
        context: { passport: true },
        variables: { accountId, first: 10 },
        onCompleted: ({ account = {} }) =>
            dispatch({ type: IDENTIFIER.LIST, payload: { identifiers: account.identifiers } }),
    });

    const fetchMoreIdentifiers = () => {
        fetchMore({
            context: { passport: true },
            variables: { accountId, cursor, first: 10 },
            updateQuery: ({ account }, { fetchMoreResult }) => {
                let { identifiers } = fetchMoreResult.account;
                dispatch({
                    type: IDENTIFIER.LIST,
                    payload: { identifiers },
                });
                return identifiers.edges.length
                    ? {
                          account: {
                              ...account,
                              identifiers: {
                                  ...identifiers,
                                  edges: [...account.identifiers.edges, ...identifiers.edges],
                              },
                          },
                      }
                    : { account };
            },
        });
    };

    useEffect(() => {
        hasNextPage && fetchMoreIdentifiers();
    }, [pageInfo]);

    if (process.env.NODE_ENV === 'development') {
        console.log('IDENTIFIERS >>>', state);
    }

    return (
        <IdentifierContext.Provider
            value={{
                ...state,
                loading,
                dispatch,
                refetch,
            }}
        >
            {children}
        </IdentifierContext.Provider>
    );
};

export const IdentifierConsumer = IdentifierContext.Consumer;
export default IdentifierContext;
