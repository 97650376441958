import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { scrollToInvalid } from '../_helpers';
import { CirclePreloader } from '.';
import { useTags } from '../hooks';

export const RenameTag = ({ tag }) => {
    const { t } = useTranslation();
    const { id: tagId } = tag;
    const [title, setTitle] = useState(tag.title);
    const { loading, updateTag } = useTags();

    const handleChange = (event) => {
        const target = event.currentTarget;
        const { value } = target;

        target.dataset.validate === 'false' && delete target.dataset.validate;
        target.setCustomValidity && target.setCustomValidity('');

        setTitle(value);
    };

    const handleSubmit = (event) => {
        event && event.preventDefault();

        if (tag.title === title) {
            return;
        }
        const form = document.forms.RenameTagForm;

        if (form.reportValidity() === false) {
            scrollToInvalid(form);
            return;
        }

        let { name } = tag;
        const parentNameMatch = name.match(/(.+)\/.+$/);
        const parentName = parentNameMatch ? `${parentNameMatch[1]}/` : '';
        name = `${parentName}${title}`;

        updateTag({ variables: { tagId, name } }).then(({ detail }) => {
            Object.keys(detail || {}).forEach((key) => {
                const validate = form[key].dataset.validate;
                if (validate !== 'false') {
                    form[key].setCustomValidity(detail[key]);
                }
                form[key].dataset.validate = false;
            });
            !detail && window.$('.close').click();
        });
    };

    return (
        <Form id="RenameTagForm" className="js-validate" onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="InputRenameTag" className="h6 small d-block text-uppercase">
                    {t('common.name')}
                </Label>
                <Input
                    id="InputRenameTag"
                    className="p-2"
                    onChange={handleChange}
                    name="name"
                    value={title}
                />
            </FormGroup>

            <FormGroup>
                <Button>
                    {loading && <CirclePreloader />}
                    {t('common.rename')}
                </Button>
            </FormGroup>
        </Form>
    );
};

export default RenameTag;
