// @flow
import React from 'react';
import * as Sentry from '@sentry/browser';
import { Button, Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SECRETS } from '../_config/constants';
import { logo } from '../assets/svg/logos';
import banner from '../assets/svg/not-found.svg';

type PropsT = {
    children: any,
};

Sentry.init({ dsn: SECRETS.sentryDsn });

class ErrorBoundary extends React.Component<PropsT> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        Sentry.withScope((scope) => {
            scope.setExtras(info);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        const { t } = this.props;
        const { hasError, eventId } = this.state;

        if (hasError) {
            //render fallback UI
            return (
                <main className="not-found">
                    <header className="position-absolute-top-0 z-index-2">
                        <nav className="d-flex justify-content-between align-items-center">
                            <div className="col-lg-5 col-xl-4 text-lg-center px-0">
                                <Link
                                    className="d-inline-block p-3 p-sm-5"
                                    to="/"
                                    aria-label="Kustodio"
                                >
                                    <img src={logo} height="42px" alt="Logo" />
                                </Link>
                            </div>
                        </nav>
                    </header>
                    <Jumbotron
                        fluid
                        className="d-flex flex-column justify-content-center align-items-center mb-0 p-0"
                        style={{ backgroundImage: `url(${banner})` }}
                    >
                        <div className="mt--5 d-flex flex-column justify-content-center align-items-center position-relative text-center z-index-2">
                            <h1
                                className="text-secondary rounded"
                                style={{ background: '#f0f1f3' }}
                            >
                                {t('error_found.title')}
                            </h1>
                            <Button
                                color="primary"
                                className="mt-4"
                                onClick={() => Sentry.showReportDialog({ eventId: eventId })}
                            >
                                {t('error_found.button')}
                            </Button>
                        </div>
                    </Jumbotron>
                </main>
            );
        }
        return this.props.children;
    }
}

const ErrorBoundaryWithTranslations = withTranslation()(ErrorBoundary);
export { ErrorBoundaryWithTranslations as ErrorBoundary };
