// @flow
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { components } from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import { Icon } from '.';
import { useAccounts, useClipboard, useTags } from '../hooks';

const Placeholder = (props) => {
    const { selectProps } = props;
    const { t } = selectProps;
    return (
        <components.Placeholder {...props}>
            <Row className="align-items-center">
                <Col className="flex-grow-0 pr-0 line-height-1">
                    <Icon type="folder" className="text-gray font-size-26" />
                </Col>
                <Col className="pl-2">
                    <span>{`${t('common.directory')}`}</span>
                </Col>
            </Row>
        </components.Placeholder>
    );
};

const Option = (props) => {
    const { data } = props;
    return (
        <components.Option {...props}>
            <Row className="align-items-center">
                <Col className="flex-grow-0 pr-0 line-height-1">
                    <Icon type="folder" className="text-black-50 font-size-26" />
                </Col>
                <Col className="pl-2">{data.label}</Col>
            </Row>
        </components.Option>
    );
};

const SingleValue = (props) => {
    const { data } = props;
    return (
        <components.SingleValue {...props}>
            <Row className="align-items-center">
                <Col className="flex-grow-0 pr-0 line-height-1">
                    <Icon type="folder" className="text-black-50 font-size-26" />
                </Col>
                <Col className="pl-2">{data.label}</Col>
            </Row>
        </components.SingleValue>
    );
};

function theme(theme) {
    return {
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#f8f9fa',
            primary: '#60baeb',
        },
    };
}

export const DirectorySelect = ({ tag: propsTag = {} }) => {
    const { t } = useTranslation();
    const [parentTag, setParentTag] = useState(propsTag.node || '');
    const { current: currentAccount = {} } = useAccounts();
    const { store } = useClipboard();
    const { createTag, loading, tags } = useTags();
    let directories = tags.edges
        .map(({ node }) => node)
        .filter(({ label }) => label && label !== '/');

    useEffect(() => {
        const newDirectory = directories.filter(
            (directory) => parentTag.name === directory.name
        )[0];
        newDirectory && handleChangeDirectory(newDirectory);
    }, [tags]);

    const handleChangeDirectory = useCallback((parentTag) => {
        setParentTag(parentTag);
    }, []);

    const handleCreateDirectory = (inputValue) => {
        const newDirectory = {
            value: `${directories.length + 1}`,
            label: inputValue,
            name: inputValue,
        };

        createTag({ variables: { accountId: currentAccount.id, name: inputValue } });
        handleChangeDirectory(newDirectory);
    };

    useEffect(() => {
        store({ parentTag });
    }, [parentTag]);

    return (
        <Creatable
            className="directory-select"
            classNamePrefix="select"
            defaultValue={parentTag}
            value={parentTag}
            noOptionsMessage={() => t('directories.no_folder')}
            formatCreateLabel={(inputLabel) => `${t('common.create')}: ${inputLabel}`}
            isDisabled={loading}
            isLoading={loading}
            isSearchable={true}
            onChange={handleChangeDirectory}
            onCreateOption={handleCreateDirectory}
            options={directories}
            t={t}
            components={{
                Option,
                Placeholder,
                SingleValue,
            }}
            theme={theme}
        />
    );
};

export default DirectorySelect;
