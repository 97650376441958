// @flow
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import ls from 'local-storage';
import Selector, { components } from 'react-select';
import { PAGES } from '../_config';
import { getTaggingIcon } from '../_helpers';
import { Icon } from '.';
import { useTaggings, useTags } from '../hooks';

const DropdownIndicator = (props) => {
    return (
        <components.DropdownIndicator {...props} className="p-0">
            <Icon type="magnify" className="px-1 font-size-26" />
        </components.DropdownIndicator>
    );
};

const IndicatorSeparator = () => {
    return null;
};

const Menu = (props: MenuPropsT) => {
    const { children, selectProps } = props;
    const { inputValue } = selectProps;
    return !inputValue ? null : <components.Menu {...props}>{children}</components.Menu>;
};

const Option = (props: ObtionPropsT) => {
    const { data, selectProps } = props;
    const { file, label } = data;
    let { inputValue = '' } = selectProps;
    inputValue = inputValue.trim().toLowerCase();
    const icon = file ? getTaggingIcon(data) : 'folder';
    return !inputValue || !label ? null : (
        <components.Option {...props} className="px-2">
            <Row className="align-items-center mx--2">
                <Col className="flex-grow-0 pr-0 line-height-1">
                    <Icon type={icon} className="text-black-50" />
                </Col>
                <Col className="pl-2">
                    {label.substr(0, label.toLowerCase().indexOf(inputValue))}
                    <strong className="font-weight-bolder">
                        {label.substr(label.toLowerCase().indexOf(inputValue), inputValue.length)}
                    </strong>
                    {label.substr(
                        label.toLowerCase().indexOf(inputValue) + inputValue.length,
                        label.length
                    )}
                </Col>
            </Row>
        </components.Option>
    );
};

const Placeholder = (props: PlaceholderPropsT) => {
    const { selectProps } = props;
    const { t } = selectProps;
    return (
        <components.Placeholder {...props}>
            <span>{`${t('common.search_all')}`}</span>
        </components.Placeholder>
    );
};

const SingleValue = (props: ValuePropsT) => {
    const { data } = props;
    const { file, label } = data;
    const icon = file ? getTaggingIcon(data) : 'folder';
    return (
        <components.SingleValue {...props}>
            <Row className="align-items-center">
                <Col className="flex-grow-0 pr-0 line-height-1">
                    <Icon type={icon} className="text-black-50" />
                </Col>
                <Col className="pl-2">{label}</Col>
            </Row>
        </components.SingleValue>
    );
};

function theme(theme) {
    return {
        ...theme,
        colors: {
            ...theme.colors,
            primary25: '#f8f9fa',
            primary: '#60baeb',
        },
    };
}

export const SearchBox = ({ className, value: propsValue }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState([]);
    const { loading: loadingTaggings, taggings = { edges: [] } } = useTaggings();
    const { loading: loadingTags, tags = { edges: [] } } = useTags();
    const loading = loadingTaggings || loadingTags;

    useEffect(() => {
        const { label } = propsValue;
        const value = !label || label === '/' || propsValue._equals({}) ? null : propsValue;
        setValue(value);
    }, [propsValue]);

    useEffect(() => {
        let mounted = true;
        const options = [
            ...taggings.edges.map(({ node }) => {
                const { id, name: label } = node;
                return { ...node, label, to: `${PAGES.FILES}/${id}` };
            }),
            ...tags.edges.map(({ node }) => node),
        ];
        mounted && options.length && setOptions(options);
        return () => {
            mounted = false;
        };
    }, [taggings.edges, tags.edges]);

    const handleSearch = (value) => {
        history.push(value.to);
        setValue({ value });
    };

    const handleFocus = () => {
        const { auth = {} } = ls('user') || {};
        const { token } = auth;
        if (!token) {
            window.location = PAGES.SIGN_IN;
        }
    };

    return (
        <Selector
            className={`search-box z-index-2 ${className ? className : ''}`}
            classNamePrefix="select"
            defaultValue={value}
            value={value}
            formatCreateLabel={(inputLabel) => `${t('common.search')}: ${inputLabel}`}
            isDisabled={loading}
            isLoading={loading}
            isSearchable={true}
            onChange={handleSearch}
            onCreateOption={handleSearch}
            options={options}
            t={t}
            onFocus={handleFocus}
            components={{
                DropdownIndicator,
                IndicatorSeparator,
                Menu,
                Option,
                Placeholder,
                SingleValue,
            }}
            theme={theme}
        />
    );
};

export default SearchBox;
