import { useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

export const useLazyQuery = (query, options) => {
    const ref = useRef();

    const [variables, runQuery] = useState(false);

    ref.current = useQuery(query, {
        ...options,
        variables,
        skip: !variables,
    });

    const runner = ({ variables }) => {
        runQuery({ first: 10, ...variables });
    };

    return [runner, ref.current];
};
