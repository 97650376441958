import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import IntlProvider from './i18n/IntlProvider';
import registerServiceWorker from './registerServiceWorker';
import { ErrorBoundary } from './components';
import { initializeCookieConsent, initializeDefines } from './_helpers';
import { createApolloClient } from './graphql';
import App from './App';

const client = createApolloClient();

registerServiceWorker();

initializeCookieConsent();
initializeDefines();

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <IntlProvider>
                <ErrorBoundary>
                    <App />
                </ErrorBoundary>
            </IntlProvider>
        </Router>
    </ApolloProvider>,
    document.getElementById('app')
);
