export * from './constants';

export const APPS = {
    DIXIBOX: 'https://dixibox.com',
    GRANSFER: 'https://gransfer.com',
};

export const PAGES = {
    ACCOUNT: '/account',
    BILLING_DATA: '/billing/data',
    BILLING_HISTORY: '/billing/history',
    BILLING_PAYMENT: '/billing/payment',
    EXIT: '/exit',
    FILES: '/app/files',
    HOME: '/',
    LEGAL_POLICIES: '/legal/policies',
    LEGAL_TERMS: '/legal/terms',
    SHARING: '/s',
    SIGN_IN_WITH_TOKEN: '/t',
    SIGN_IN: '/signin',
    SIGN_UP: '/signup',
    SUBSCRIPTION: '/subscription',
    TAGS: '/app',
};
