import React from 'react';

import { Col, Container, Row } from 'reactstrap';

import { Language } from './';

import { logoMin } from '../assets/svg/logos';

export const Footer = () => (
    <footer>
        <Container className="container border-top mt-5 py-5">
            <Row className="m-0">
                <Col className="pl-0">
                    <img alt="logo Kustodio" src={logoMin} height="42px" />
                </Col>
                <Col className="pr-0 text-right">
                    <Language direction="up" toggleClassName="border-0" menu={{ right: true }} />
                </Col>
            </Row>
            <small>
                <small className="d-block mt-2 mb-3 text-muted">
                    © {new Date().getFullYear()} KUSTODIO
                    <br />
                    All rights reserved
                </small>
            </small>
        </Container>
    </footer>
);
