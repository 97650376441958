import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { Button } from 'reactstrap';
import { JsonEditor } from 'jsoneditor-react';
import ls from 'local-storage';
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import Ajv from 'ajv';
import { URL } from '../_config';
import { humanFileSize, unzip } from '../_helpers';
import { Icon, PreviewWrapper } from '../components';
import { useTaggings, useTags } from '../hooks';

const ajv = new Ajv({
    $data: true,
    allErrors: true,
    jsonPointers: true,
    removeAdditional: true,
    uniqueItems: true,
});

const FilePreview = (props) => {
    const {
        content = '',
        createdAt = '',
        extension = '',
        mimeType = '',
        name = '',
        size = '',
        url = '',
        blob = {},
        onChange,
    } = props;
    const { t } = useTranslation();
    const {
        current: currentTagging = {},
        showTagging = () => {},
        taggings = { edges: [] },
    } = useTaggings();
    const { current: currentTag = {} } = useTags();
    const preview = useRef();
    const [value, setValue] = useState({});
    const newValue = useRef(value);
    const ext = name.split('.').pop();

    const onLoad = () => {
        let parent = preview.current;
        const { firstElementChild: child } = parent;
        child.style.transform = '';
        if (child.offsetWidth > parent.offsetWidth) {
            child.style.transform = `scale(${parent.offsetWidth / child.offsetWidth - 0.05})`;
        }
        if (child.offsetHeight > parent.offsetHeight) {
            child.style.transform = `scale(${parent.offsetHeight / child.offsetHeight - 0.05})`;
        }
    };

    let type = mimeType.replace(/\/.*$/, '');
    if (type === 'application') {
        type = mimeType.replace(/^.*\//, '');
    }

    const renderControls = useCallback(() => {
        const { next, prev } = taggings.edges
            .filter(({ node: { tag } }) => tag.id === currentTag.id)
            .reduce(
                (response, tagging) => {
                    const { next, prev, prevTagging = {} } = response;
                    const { current: prevIsCurrent } = prevTagging;
                    const { current } = tagging;
                    return {
                        next: next || (prevIsCurrent && tagging.node) || false,
                        prev: prev || (current && prevTagging.node) || false,
                        prevTagging: tagging,
                    };
                },
                { next: false, prev: false }
            );
        return (
            <>
                {prev && (
                    <Button
                        id="PrevTaggingButton"
                        color="link"
                        className="btn-prev shadow-none"
                        onClick={() => showTagging(prev)}
                    >
                        <Icon type="chevron-left" />
                    </Button>
                )}
                {next && (
                    <Button
                        id="NextTaggingButton"
                        color="link"
                        className="btn-next shadow-none"
                        onClick={() => showTagging(next)}
                    >
                        <Icon type="chevron-right" />
                    </Button>
                )}
            </>
        );
    }, [currentTagging, taggings]);

    const Preview = useCallback(
        ({ ext, name, url, value }) => {
            switch (ext) {
                case 'mp3':
                case 'mpga':
                    return <audio controls src={url} />;

                case 'pdf':
                    return <iframe title={name} src={url} />;

                case 'xml':
                case 'txt':
                    return <textarea key={content} defaultValue={content} />;

                case 'json':
                    return (
                        <>
                            <JsonEditor
                                name={name}
                                value={value}
                                mode="code"
                                htmlElementProps={{ className: 'json-editor' }}
                                onChange={(json) => {
                                    newValue.current = json;
                                    document
                                        .querySelector('.actions .save')
                                        .classList.remove('disabled');
                                }}
                                ajv={ajv}
                                ace={ace}
                                theme="ace/theme/github"
                                schema={{}}
                            />
                            <Button
                                className="save d-none"
                                onClick={() => {
                                    const json = JSON.stringify(newValue.current);
                                    const file = new Blob([json], { type: 'application/json' });
                                    setValue(newValue.current);
                                    onChange(name, file);
                                }}
                            ></Button>
                        </>
                    );

                case 'png':
                case 'gif':
                case 'jpg':
                case 'jpeg':
                case 'svg':
                    return <img className="img" src={url} alt={name} />;

                case 'mp4':
                    return <video controls src={url} />;

                case 'bin':
                case 'otf':
                case 'ttf':
                    const fontFamily = name.replace('-', '').split('.')[0];
                    return (
                        <div>
                            <style>{`
                                @font-face {
                                    font-family: ${fontFamily};
                                    src: url(${url});
                                }
                            `}</style>
                            <div style={{ fontFamily, fontSize: '3rem' }}>Ag</div>
                        </div>
                    );

                case 'zip':
                    if (/\.cdz$/.test(name)) {
                        const { auth: { token } = {} } = ls('user') || {};
                        const link = document.createElement('a');
                        link.href = `${URL.CODOOZER}/t/${token}/n/${name}/u/${url}`;
                        link.target = '_blank';
                        link.click();
                        window.$('.close').click();
                    }
                    if (/\.cdzp$/.test(name)) {
                        return <PreviewWrapper entries={value._equals({}) ? [] : value} />;
                    }
                default:
                    return (
                        <div className="no-preview">
                            <Icon type="mdi-file" className="font-size-32 text-primary" />
                            <h6 className="m-0">{t('files.no_preview')}</h6>
                        </div>
                    );
            }
        },
        [url, value]
    );

    useEffect(() => {
        let mounted = true;
        if (ext === 'json') {
            document.querySelector('.actions .save').classList.add('disabled');
            fetch(url)
                .then((resp) => resp.json())
                .then((json) => {
                    mounted && setValue(json);
                })
                .catch((e) => {
                    message.error({
                        message: t('files.error_loading'),
                        description: e,
                        open: true,
                    });
                });
        } else if (ext === 'cdzp') {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    if (mounted) {
                        unzip(blob).then((entries) => {
                            setValue(entries);
                        });
                    }
                })
                .catch((e) => {
                    message.error({
                        message: t('files.error_loading'),
                        description: e,
                        open: true,
                    });
                });
        } else if (url) {
            preview.current.firstElementChild &&
                preview.current.firstElementChild.addEventListener('load', onLoad, false);
        }
        return () => {
            mounted = false;
            preview.current.firstElementChild &&
                preview.current.firstElementChild.removeEventListener('load', onLoad, false);
        };
    }, [preview.current, url]);

    return (
        <div className="preview" ref={preview}>
            <div className="preview-body" data-type={type}>
                {type !== 'text' && renderControls()}
                <Preview ext={extension} name={name} url={url} value={value} />
            </div>
            <div className="preview-footer">
                <div>
                    <small className="text-muted">{t('common.extension')}</small>
                    <span>{extension.toUpperCase()}</span>
                </div>
                <div>
                    <small className="text-muted">{t('common.size')}</small>
                    <span>{humanFileSize(size)}</span>
                </div>
                <div>
                    <small className="text-muted">{t('common.creation_date')}</small>
                    <span>{createdAt ? new Date(createdAt).toLocaleString() : '-'}</span>
                </div>
            </div>
        </div>
    );
};

export default FilePreview;
export { FilePreview };
