export const ACCOUNT = {
    INITIAL_STATE: {
        accounts: { edges: [] },
        current: undefined,
    },

    CREATE: 'ACCOUNT_CREATE',
    DELETE: 'ACCOUNT_DELETE',
    ERROR: 'ACCOUNT_ERROR',
    GET: 'ACCOUNT_GET',
    LIST: 'ACCOUNT_LIST',
    SELECT: 'ACCOUNT_SELECT',
};

export const AccountReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case ACCOUNT.CREATE:
            //draft.accounts = [...draft.accounts, payload.account];
            break;

        case ACCOUNT.DELETE:
            draft.accounts = draft.accounts.filter(({ node }) => node.id !== payload.id);
            break;

        case ACCOUNT.ERROR:
            draft.error = payload.error;
            break;

        case ACCOUNT.GET:
            draft.accounts = draft.accounts.map((account) => {
                let { node } = account;
                let { id } = node;
                return id !== payload.account.id ? account : payload.account;
            });

            break;

        case ACCOUNT.LIST:
            draft.accounts = payload.accounts.edges.map((accountEdge) => ({
                ...accountEdge,
                node: {
                    ...accountEdge.node,
                    identifiers: accountEdge.node.identifiers.edges,
                },
            }));
            draft.current = payload.accounts.edges[0].node;
            draft.pageInfo = payload.accounts.pageInfo;
            break;

        case ACCOUNT.SELECT:
            draft.current = payload.account.node;
            break;

        default:
            return draft;
    }
};
