import { directoriesFormat, findInTree, tagsFormat } from '../../_helpers';

export const TAGS = {
    INITIAL_STATE: {
        checkedTags: [],
        current: undefined,
        currentDir: undefined,
        hierarchical: [],
        tags: { edges: [] },
    },
    CHECK: 'TAG_CHECK',
    CHECK_ALL: 'TAGS_CHECK_ALL',
    CLEAR: 'TAGS_CLEAR',
    COPY: 'TAGS_COPY',
    CREATE_TAG: 'TAG_CREATE_TAG',
    CUT: 'TAGS_CUT',
    DELETE_TAG: 'TAG_DELETE_TAG',
    LIST_TAGS: 'TAG_LIST_TAGS',
    SELECT: 'TAG_SELECT',
    UNCHECK: 'TAG_UNCHECK',
    UNCHECK_ALL: 'TAGS_UNCHECK_ALL',
    UPDATE_TAG: 'TAGS_UPDATE_TAG',
};

export const TagsReducer = (draft, action) => {
    const { payload, type } = action;
    switch (type) {
        case TAGS.CHECK: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) =>
                    payload.tags.find(({ node }) => node.id === tag.node.id)
                        ? { ...tag, checked: true }
                        : tag
                ),
            };
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGS.CHECK_ALL: {
            const regex = new RegExp(
                `^${
                    payload.tag.name === '/'
                        ? ''
                        : payload.tag.name.replace('(', '\\(').replace(')', '\\)')
                }/?[^/]+$`
            );
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) => ({
                    ...tag,
                    checked: regex.test(tag.node.name),
                })),
            };
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGS.CLEAR: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) => ({
                    ...tag,
                    checked: false,
                    current: tag.node.name === '/',
                })),
            };
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            draft.currentDir = findInTree(draft.current, draft.hierarchical);
            break;
        }

        case TAGS.COPY: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) => ({
                    ...tag,
                    checked: false,
                    copied: payload.tags.some(({ node: { id } }) => id === tag.node.id),
                    cuted: false,
                })),
            };
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGS.CREATE_TAG: {
            draft.tags = {
                ...draft.tags,
                edges: [
                    ...(!draft.tags.edges.filter(({ node: tag }) => tag.id === payload.createTag.id)
                        .length
                        ? tagsFormat([
                              { node: { ...payload.createTag, current: !!payload.current } },
                          ])
                        : []),
                    ...draft.tags.edges,
                ].sort(({ node: a }, { node: b }) => (a.name < b.name ? -1 : 1)),
            };
            draft.hierarchical = directoriesFormat(draft.tags.edges);
            break;
        }

        case TAGS.CUT: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) => ({
                    ...tag,
                    checked: false,
                    copied: false,
                    cuted: payload.tags.some(({ id }) => id === tag.node.id),
                })),
            };
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGS.DELETE_TAG: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.filter(({ node: { id } }) => id !== payload.deleteTag.id),
            };
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            draft.hierarchical = directoriesFormat(draft.tags.edges);
            draft.currentDir = findInTree(draft.current, draft.hierarchical);
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGS.LIST_TAGS: {
            draft.tags = {
                ...draft.tags,
                ...payload.tags,
                edges: tagsFormat(
                    draft.tags.edges.concat(
                        payload.tags.edges.filter(
                            ({ node: a }) => !draft.tags.edges.some(({ node: b }) => a.id === b.id)
                        )
                    )
                ).map((tag) => ({
                    ...tag,
                    current: (!draft.current && tag.node.name === '/') || tag.current,
                })),
            };
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            draft.hierarchical = directoriesFormat(draft.tags.edges);
            draft.currentDir = findInTree(draft.current, draft.hierarchical);
            draft.pageInfo = payload.tags.pageInfo;
            break;
        }

        case TAGS.MOVE_SUCCESS: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) => ({
                    ...tag,
                    checked: false,
                    copied: false,
                    cuted: false,
                    loading: false,
                })),
            };
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            break;
        }

        case TAGS.SELECT: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) => ({
                    ...tag,
                    current: tag.node.id === payload.tag.id,
                })),
            };
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            draft.currentDir = findInTree(draft.current, draft.hierarchical);
            break;
        }

        case TAGS.UNCHECK: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) =>
                    payload.tags.find(({ node }) => node.id === tag.node.id)
                        ? { ...tag, checked: false }
                        : tag
                ),
            };
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            break;
        }

        case TAGS.UNCHECK_ALL: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) => ({ ...tag, checked: false })),
            };
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            break;
        }

        case TAGS.UPDATE_TAG: {
            draft.tags = {
                ...draft.tags,
                edges: draft.tags.edges.map((tag) =>
                    payload.updateTag.id === tag.node.id
                        ? tagsFormat([
                              {
                                  ...tag,
                                  node: { ...tag.node, ...payload.updateTag },
                              },
                          ])[0]
                        : tag
                ),
            };
            draft.checkedTags = draft.tags.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            draft.current = (draft.tags.edges.find(({ current }) => current) || {}).node;
            draft.hierarchical = directoriesFormat(draft.tags.edges);
            draft.currentDir = findInTree(draft.current, draft.hierarchical);
            break;
        }

        default: {
            return draft;
        }
    }
};
