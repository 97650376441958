export const fileBlackList = ['.DS_Store', 'thumbs.db'];

export function initializeDefines() {
    // eslint-disable-next-line
    Object.defineProperty(Object.prototype, '_clone', {
        enumerable: false,
        value: function() {
            return JSON.parse(JSON.stringify(this));
        },
    });

    // eslint-disable-next-line
    Object.defineProperty(Object.prototype, '_equals', {
        enumerable: false,
        value: function(obj) {
            var p;
            if (this === obj) {
                return true;
            }

            // some checks for native types first

            // function and sring
            if (typeof this === 'function' || typeof this === 'string' || this instanceof String) {
                return this.toString() === obj.toString();
            }

            // number
            if (this instanceof Number || typeof this === 'number') {
                if (obj instanceof Number || typeof obj === 'number') {
                    return this.valueOf() === obj.valueOf();
                }
                return false;
            }

            // null._equals(null) and undefined._equals(undefined) do not inherit from the
            // Object.prototype so we can return false when they are passed as obj
            if (typeof this !== typeof obj || obj === null || typeof obj === 'undefined') {
                return false;
            }

            function sort(o) {
                var result = {};

                if (o === null || typeof o !== 'object') {
                    return o;
                }

                Object.keys(o)
                    .sort()
                    .forEach(function(key) {
                        result[key] = sort(o[key]);
                    });

                return result;
            }

            if (typeof this === 'object') {
                if (Array.isArray(this)) {
                    // check on arrays
                    return JSON.stringify(this) === JSON.stringify(obj);
                } else {
                    // anyway objects
                    for (p in this) {
                        if (typeof this[p] !== typeof obj[p]) {
                            return false;
                        }
                        if ((this[p] === null) !== (obj[p] === null)) {
                            return false;
                        }
                        switch (typeof this[p]) {
                            case 'undefined':
                                if (typeof obj[p] !== 'undefined') {
                                    return false;
                                }
                                break;
                            case 'object':
                                if (
                                    this[p] !== null &&
                                    obj[p] !== null &&
                                    (this[p].constructor.toString() !==
                                        obj[p].constructor.toString() ||
                                        !this[p]._equals(obj[p]))
                                ) {
                                    return false;
                                }
                                break;
                            case 'function':
                                if (this[p].toString() !== obj[p].toString()) {
                                    return false;
                                }
                                break;
                            default:
                                if (this[p] !== obj[p]) {
                                    return false;
                                }
                        }
                    }
                }
            }

            // at least check them with JSON
            return JSON.stringify(sort(this)) === JSON.stringify(sort(obj));
        },
    });

    //eslint-disable-next-line
    Date.prototype.toW3CString = function() {
        var f = this.getFullYear();
        var e = this.getMonth();
        e++;
        if (e < 10) {
            e = '0' + e;
        }
        var g = this.getDate();
        if (g < 10) {
            g = '0' + g;
        }
        var h = this.getHours();
        if (h < 10) {
            h = '0' + h;
        }
        var c = this.getMinutes();
        if (c < 10) {
            c = '0' + c;
        }
        var j = this.getSeconds();
        if (j < 10) {
            j = '0' + j;
        }
        var d = -this.getTimezoneOffset();
        var b = Math.abs(Math.floor(d / 60));
        var i = Math.abs(d) - b * 60;
        if (b < 10) {
            b = '0' + b;
        }
        if (i < 10) {
            i = '0' + i;
        }
        var a = '+';
        if (d < 0) {
            a = '-';
        }
        return f + '-' + e + '-' + g + 'T' + h + ':' + c + ':' + j + a + b + ':' + i;
    };
}
