// @flow
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { message } from 'antd';
import { PAGES } from './_config';
import { Doyo, DropWrapper, Passport } from './components';
import { ContextProviders } from './context';
import {
    DirectoriesPage,
    HomePage,
    LoginPage,
    NotFoundPage,
    PoliciesPage,
    SharingPage,
    TermsPage,
} from './pages';
import './assets/scss/main.scss';

message.config({
    right: 0,
    duration: 3,
});

function App() {
    return (
        <ContextProviders>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path={PAGES.HOME} component={HomePage} />
                <Doyo exact path={PAGES.SIGN_IN} />
                <Doyo exact path={PAGES.SIGN_UP} />
                <Doyo exact path={PAGES.ACCOUNT} />
                <Doyo exact path={PAGES.BILLING_DATA} />
                <Doyo exact path={PAGES.BILLING_HISTORY} />
                <Doyo exact path={PAGES.BILLING_PAYMENT} />
                <Doyo exact path={PAGES.SUBSCRIPTION} />
                <Route exact path={`${PAGES.SIGN_IN_WITH_TOKEN}/:token/`} component={LoginPage} />
                <Route
                    exact
                    path={`${PAGES.SIGN_IN_WITH_TOKEN}/:token/:redirect(.+)`}
                    component={LoginPage}
                />
                <Passport exact path={`${PAGES.FILES}/:file(.+)`} component={SharingPage} />
                <Passport exact path={PAGES.TAGS} component={DirectoriesPage} />
                <Passport exact path={`${PAGES.TAGS}/:tag(.+)`} component={DirectoriesPage} />
                <Route exact path={PAGES.LEGAL_TERMS} component={TermsPage} />
                <Route exact path={PAGES.LEGAL_POLICIES} component={PoliciesPage} />
                <Route exact path={`${PAGES.SHARING}/:token`} component={SharingPage} />
                <Route exact path="*" component={NotFoundPage} />
            </Switch>
            <DropWrapper />
        </ContextProviders>
    );
}

export default App;
