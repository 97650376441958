import React from 'react';
import { TaggingsProvider } from './Taggings';
import { ViewerProvider } from './Viewer';

export const ContextProviders = ({ children }) => {
    return (
        <ViewerProvider>
            <TaggingsProvider>
                <>{children}</>
            </TaggingsProvider>
        </ViewerProvider>
    );
};

export * from './Account';
export * from './Clipboard';
export * from './Identifier';
export * from './Viewer';
export * from './Taggings';
export * from './Tags';
export * from './Timeline';
