import { i18n } from '../i18n';
import 'cookieconsent';
export function initializeCookieConsent() {
    if (/^\/s\//.test(window.location.pathname)) {
        return;
    }
    window.cookieconsent.initialise({
        palette: {
            popup: {
                background: '#fce359',
            },
            button: {
                background: '#365C6C',
            },
        },
        theme: 'classic',
        content: i18n.t('cookieconsent', { returnObjects: true }),
    });
}
