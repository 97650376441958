import gravatar from 'gravatar-api';
import JSZip from 'jszip';
import { PAGES } from '../_config';
import { i18n } from '../i18n';
import mimeTypes from './mimeTypes.json';

const fileIcons = {
    audio: 'file-music',
    image: 'file-image',
    pdf: 'file-pdf',
    video: 'file-video',
};

const _navigator = {
    isSafari: () => {
        const userAgent = navigator.userAgent;
        return !(
            userAgent === false ||
            !userAgent.includes('AppleWebKit') ||
            userAgent.includes('OmniWeb') ||
            userAgent.includes('Shiira') ||
            userAgent.includes('SymbianOS') ||
            userAgent.includes('Android') ||
            userAgent.includes('iPhone') ||
            userAgent.includes('iPad') ||
            userAgent.includes('iPod') ||
            userAgent.includes('Chrome') ||
            userAgent.includes('Chrome')
        );
    },
    isSafariIOS: () => {
        const userAgent = navigator.userAgent;
        return !(
            userAgent === false ||
            !userAgent.includes('AppleWebKit') ||
            !userAgent.includes('Safari') ||
            (!userAgent.includes('iPhone') &&
                !userAgent.includes('iPad') &&
                !userAgent.includes('iPod'))
        );
    },
};

export const downloadBlob = (blob, name) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.setAttribute('download', name);
    a.click();
};

export const scrollToInvalid = (form) => {
    form.reportValidity();
    const elements = form.querySelectorAll('input,select,textarea,checkbox');
    let scrollToElement = null;
    Object.keys(elements).some((i) => {
        const el = elements[i];
        if (!el.validity.valid) {
            scrollToElement = el;
            return true;
        }
        return false;
    });
    scrollToElement && scrollToElement.scrollIntoView({ behavior: 'smooth' });
};

export const accountsFormat = (accounts) => {
    return accounts.map((account) => ({
        ...account,
        name: account.name || account.identifier,
    }));
};

export const contactsFormat = (contacts) => {
    return contacts.map((contact) => ({
        ...contact,
        value: contact.identifier_subject,
        label: contact.identifier_subject,
        icon: 'contact',
    }));
};

export const getTaggingIcon = (tagging) => {
    const { file = {} } = tagging;
    const { mimeType = '' } = file;
    let type = mimeType.replace(/\/.*$/, '');
    if (type === 'application') {
        type = mimeType.replace(/^.*\//, '');
    }
    return fileIcons[type] || 'file';
};

export const mobileCheck = () => {
    let check = false;
    (function(a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

export const oggSupports = () => {
    return !(_navigator.isSafari() || _navigator.isSafariIOS());
};

export const paginationFormat = (headers = {}) => {
    const pagination = {
        total: headers['x-total-count'],
    };
    const regexp = new RegExp(/<([^;]*)>; rel="([^"]*)"/g);
    headers.link && headers.link.replace(regexp, (str, url, which) => (pagination[which] = url));
    return pagination;
};

export const appendToTree = (parent, directoryToAppend) => {
    const { children = [] } = parent;
    const to = new RegExp(`^${parent.to}/[^/]*$`);

    if (directoryToAppend.to.match(to)) {
        parent.children = [...children, directoryToAppend].sort((a, b) =>
            a.title < b.title ? -1 : 1
        );
    } else {
        if (Array.isArray(parent)) {
            parent = parent.map((child) => appendToTree(child, directoryToAppend));
        } else {
            parent.children = children.map((child) => appendToTree(child, directoryToAppend));
        }
    }

    return parent;
};

export const findInTree = (object = {}, tree = []) => {
    let leaf = undefined;
    tree.some((node) => {
        if (node.id === (object || {}).id) {
            leaf = node;
            return true;
        }
        leaf = findInTree(object, node.children);
        return !!leaf;
    });
    return leaf;
};

export const removeFromTree = (parent, directoryToRemove) => {
    const { children = [] } = parent;
    if (Array.isArray(parent)) {
        parent = parent
            .filter((child) => child.id !== directoryToRemove.id)
            .map((child) => removeFromTree(child, directoryToRemove));
    } else {
        parent.children = children
            .filter((child) => child.id !== directoryToRemove.id)
            .map((child) => removeFromTree(child, directoryToRemove));
    }
    return parent;
};

export const tagsFormat = (edges = []) => {
    return edges
        .sort(({ node: a }, { node: b }) => (a.name < b.name ? -1 : 1))
        .map((tag) => {
            const { node } = tag;
            const { name } = node;
            const parents = name.length === 1 ? [name] : name.split('/');
            return {
                ...tag,
                node: {
                    ...node,
                    label: name,
                    title: parents.pop(),
                    to: encodeURI(`${PAGES.TAGS}${name && name !== '/' ? `/${name}` : ''}`),
                    value: name,
                },
            };
        });
};

export const directoriesFormat = (edges = []) => {
    const aux = {};
    const directories = {};
    try {
        const tags = JSON.parse(JSON.stringify(edges));
        tags.reverse().forEach(({ node: tag }) => (aux[tag.name] = tag));

        while (Object.keys(aux).length) {
            const directory = aux[Object.keys(aux)[0]];
            const { name } = directory;
            const parents = name.length === 1 ? [name] : name.split('/');
            const title = parents.pop();

            if (!parents.length) {
                directories[name] = {
                    ...directories[name],
                    ...aux[name],
                    ...directory,
                    children: [
                        ...(directory.children || []),
                        ...((directories[name] && directories[name].children) || []),
                    ],
                };
                delete aux[name];
                continue;
            }
            const parentName = name.replace(`/${title}`, '');
            aux[parentName] = {
                ...directories[parentName],
                ...aux[parentName],
                name: parentName,
                children: [...[directory], ...((aux[parentName] && aux[parentName].children) || [])]
                    .filter(({ id }) => !!id)
                    .sort((a, b) => (a.name < b.name ? -1 : 1)),
            };
            delete aux[directory.name];
        }
    } catch (e) {
        console.error(e);
        throw new Error('Directories could not be loaded.');
    }

    const children = Object.values(directories)
        .reverse()
        .filter(({ title }) => title !== '/');
    directories['/'] = {
        to: PAGES.TAGS,
        ...directories['/'],
        icon: 'folder-home',
        title: 'Home',
        children,
    };
    return directories['/'] ? [directories['/']] : children;
};

export const getLanguage = () => {
    return i18n.language.split('-')[0];
};

export const getMimeType = (ext) => {
    return mimeTypes[ext] || '';
};

const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));
const convertHexUnitTo256 = (hexStr) => parseInt(hexStr.repeat(2 / hexStr.length), 16);
const getAlphafloat = (a, alpha) => {
    if (typeof a !== 'undefined') {
        return a / 256;
    }
    if (typeof alpha !== 'undefined') {
        if (alpha > 1 && alpha <= 100) {
            return alpha / 100;
        }
        if (alpha >= 0 && alpha <= 1) {
            return alpha;
        }
    }
    return 1;
};
const hexToRGBA = (hex, alpha) => {
    if (!isValidHex(hex)) {
        return;
    }
    const chunkSize = Math.floor((hex.length - 1) / 3);
    let hexArr = getChunksFromString(hex.slice(1), chunkSize);
    hexArr = hexArr.length === 3 ? ['ff', ...hexArr] : hexArr;
    const [a, r, g, b] = hexArr.map(convertHexUnitTo256);
    return `rgba(${r}, ${g}, ${b}, ${getAlphafloat(a, alpha)})`;
};
const rgbaToHex = (color) => {
    let [r, g, b, a = 1] = color
        .replace(/[ rgba?()]/g, '')
        .split(',')
        .map((s) => parseFloat(s));
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (a.length === 1) a = '0' + a;
    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return `#${a}${r}${g}${b}`;
};
export const getHex = (color) => {
    if (!color) {
        return;
    }
    if (color.includes('#')) {
        return color;
    }
    return rgbaToHex(color);
};
export const getRgba = (color, alpha) => {
    if (!color) {
        return;
    }
    if (!color.includes('#')) {
        return color;
    }
    return hexToRGBA(color, alpha);
};

export const getTextColor = (bgColor, lightColor = '#ffffff', darkColor = '#000000') => {
    if (!bgColor) {
        return;
    }
    const chunkSize = Math.floor((bgColor.length - 1) / 3);
    let hexArr = getChunksFromString(bgColor.slice(1), chunkSize);
    hexArr = hexArr.length === 3 ? ['ff', ...hexArr] : hexArr;
    const [, r, g, b] = hexArr.map(convertHexUnitTo256);

    const uicolors = [r / 255, g / 255, b / 255];
    const c = uicolors.map((col) => {
        if (col <= 0.03928) {
            return col / 12.92;
        }
        return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L > 0.179 ? darkColor : lightColor;
};

export const getUniqueName = (name, options = {}) => {
    const { isId = false, names } = options;
    let uniqueName = name;
    if (isId) {
        uniqueName = uniqueName.replace(/ /g, '_').replace(/[()]*/g, '');
    }
    if (!names.includes(uniqueName)) {
        return uniqueName;
    }
    const extensionMatch = name.match(/\.(.+)$/);
    const extension = extensionMatch ? `.${extensionMatch[1]}` : '';
    if (isId) {
        const num = parseInt((name.match(/_(\d+)(\..)?$/) || [null, 0])[1], 10);
        uniqueName = `${name.replace(/(_\d+)*(\..+)?$/, '')}_${num + 1}${extension}`;
    } else {
        const num = parseInt((name.match(/\((\d+)\)/) || [null, 0])[1], 10);
        uniqueName = `${name.replace(/( \(\d+\))*(\..+){0,1}$/, '')} (${num + 1})${extension}`;
    }

    return getUniqueName(uniqueName, options);
};

export const setExpandedDirectories = (directories, prevDirectories = []) => {
    if (!directories || !directories.length) {
        return [];
    }

    const path = encodeURI(decodeURI(window.location.pathname));
    return directories.map((directory) => {
        const { current, to } = directory;
        const prevDirectory = prevDirectories.find((dir) => dir.to === to) || {};
        return {
            ...directory,
            children: setExpandedDirectories(directory.children, prevDirectory.children),
            expanded:
                (current && path !== to) ||
                (typeof prevDirectory.expanded !== 'undefined'
                    ? prevDirectory.expanded
                    : path.includes(to) && path !== to),
        };
    });
};

export const findDirectoryByPathname = (directories = [], pathname) => {
    let tag = {};
    directories.some((directory) => {
        if (directory.to === pathname) {
            tag = directory;
            return true;
        }
        tag = findDirectoryByPathname(directory.children, pathname);
        return !!tag.id;
    });

    pathname = pathname.replace(/\/[^/]+$/, '');
    if (!tag.id && pathname) {
        tag = findDirectoryByPathname(directories, pathname);
    }

    return tag;
};

export const findDirectory = (directories = [], tag) => {
    if (!tag) {
        return undefined;
    }
    let directory = {};
    directories.some((_directory) => {
        if (_directory.id === tag.id) {
            directory = _directory;
            return true;
        }
        directory = findDirectory(_directory.children, tag);
        return !!directory.id;
    });

    return directory;
};

export const getAvatar = (parameters = {}) => {
    const { email, size = 200, secure = true, d = 'retro' } = parameters;
    return gravatar.imageUrl({
        email,
        parameters: { size, d },
        secure,
    });
};

export const getIdentifierType = (identifier) => {
    if (
        identifier.match(
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gim
        )
    ) {
        return 'email';
    } else if (
        identifier.match(
            /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/gim
        )
    ) {
        return 'domain';
    } else if (identifier.match(/^(?!^\d+$)[a-zA-Z0-9]+([_-]?[a-zA-Z0-9])*$/gim)) {
        return 'alias';
    }
    return 'telephone';
};

export const getSelectionText = () => {
    var text = '';
    if (window.getSelection) {
        text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
        text = document.selection.createRange().text;
    }
    return text;
};

export const getUnique = (arr, attribute = 'id') => {
    return (
        arr
            .map((e) => e[attribute])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            // eliminate the dead keys & store unique objects
            .filter((e) => arr[e])
            .map((e) => arr[e])
    );
};

export const humanFileSize = (bytes) => {
    const thresh = 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
};

export const isColor = (str) => {
    str = `${str}`;
    return str && (str[0] === '#' || str.includes('rgb'));
};

export const isDate = (d) => d instanceof Date;

export const isEmpty = (o) => Object.keys(o).length === 0;

export const isFont = (file) => {
    const { name } = file;
    const ext = name.split('.').pop();
    return ['eot', 'otf', 'ttf', 'woff', 'woff2'].includes(ext);
};

export const isObject = (o) => o !== null && typeof o === 'object';

export const isPercentage = (str) => {
    const num = parseInt(str, 10);
    return str && str[str.length - 1] === '%' && num >= 0 && num <= 100;
};

export const isProjectFile = (file) => {
    const { name = '', type = '' } = file;
    const ext = name.split('.').pop();
    return type.includes('zip') || ext === 'zip' || ext === 'cdz';
};

export const isZip = (file) => {
    const { name = '', type = '' } = file;
    const ext = name.split('.').pop();
    return type === 'application/zip' || ext === 'zip';
};

export const speak = (text = '', lang = 'en') => {
    const voices = speechSynthesis.getVoices();
    const u = new SpeechSynthesisUtterance();
    u.text = text;
    u.lang = lang;
    u.voice =
        voices.find((voice) => !voice.localService && voice.lang.includes(lang)) ||
        voices.find((voice) => voice.lang.includes(lang));
    u.voiceURI = 'native';
    speechSynthesis.speak(u);
};

export const translate = (sourceText = '', targetLang = 'en', sourceLang = 'auto') =>
    new Promise((resolve) => {
        if (sourceLang === targetLang) {
            resolve(sourceText);
            return;
        }
        const url = `https://translate.googleapis.com/translate_a/single?client=gtx&sl=${sourceLang}&tl=${targetLang}&dt=t&q=${encodeURI(
            sourceText
        )}`;

        window
            .fetch(url)
            .then((response) => response.json())
            .then((json) => {
                resolve(json[0][0][0]);
            });
    });

export const unzip = (zip) =>
    new Promise((resolve, reject) => {
        (async () => {
            try {
                const uncompressedZip = await JSZip.loadAsync(zip);
                const { files = {} } = uncompressedZip;
                const entries = (
                    await Promise.all(
                        Object.values(files).map(async (file) => {
                            const { name } = file;
                            return file.async('blob').then(async (blob) => {
                                if (!blob.size) {
                                    return;
                                }
                                const ext = name.split('.').pop();
                                blob = blob.slice(0, blob.size, getMimeType(ext));
                                const url = window.URL.createObjectURL(blob);
                                return {
                                    name,
                                    blob,
                                    url,
                                };
                            });
                        })
                    )
                ).filter((entry) => entry);
                resolve(entries);
            } catch (e) {
                console.error('>>>', e);
            }
        })();
    });
