import React, { createContext, useCallback } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useImmerReducer } from 'use-immer';
import { message, Progress } from 'antd';
import { getUniqueName } from '../_helpers';
import { LIST_TAGGINGS, SHOW_TAGGING, SHOW_TAGGING_WITH_URL } from '../graphql/queries';
import { TAGGINGS, TaggingsReducer } from './reducers';
import {
    CREATE_FILE,
    CREATE_SHARING,
    CREATE_TAG,
    CREATE_TAGGING,
    DELETE_SHARING,
    DELETE_TAGGING,
    REPLACE_TAGGING_FILE,
    UPDATE_TAGGING,
} from '../graphql/mutations';
import { useTags } from '../hooks';

export const TaggingsContext = createContext({});

export const TaggingsProvider = ({ children }) => {
    const [state, dispatch] = useImmerReducer(TaggingsReducer, TAGGINGS.INITIAL_STATE);
    const { pageInfo = {}, taggings } = state;
    const { endCursor: cursor, hasNextPage = true } = pageInfo;
    const { current: currentTag = {} } = useTags();
    const { id: tagId } = currentTag;

    const checkAllTaggings = (tag) => {
        dispatch({ type: TAGGINGS.CHECK_ALL, payload: { tag } });
    };

    const checkTaggings = (taggings) => {
        dispatch({ type: TAGGINGS.CHECK, payload: { taggings } });
    };

    const clearTaggings = () => {
        dispatch({ type: TAGGINGS.CLEAR });
    };

    const copyTaggings = (taggings) => {
        dispatch({ type: TAGGINGS.COPY, payload: { taggings } });
    };

    const [createFile] = useMutation(CREATE_FILE, {
        onCompleted: ({ createFile }) => {
            dispatch({ type: TAGGINGS.CREATE_FILE, payload: { tagging: createFile } });
        },
    });

    const [createSharing, { loading: loadingCreateSharing }] = useMutation(CREATE_SHARING, {
        onCompleted: ({ createSharing }) => {
            dispatch({ type: TAGGINGS.CREATE_SHARING, payload: { createSharing } });
        },
    });

    const [createTagging, { loading: loadingCreateTagging }] = useMutation(CREATE_TAGGING, {
        onCompleted: ({ createTagging }) => {
            dispatch({ type: TAGGINGS.CREATE_TAGGING, payload: { tagging: createTagging } });
        },
    });

    const cutTaggings = (taggings) => {
        dispatch({ type: TAGGINGS.CUT, payload: { taggings } });
    };

    const [deleteSharing, { loading: loadingDeleteSharing }] = useMutation(DELETE_SHARING, {
        onCompleted: ({ deleteSharing }) => {
            dispatch({ type: TAGGINGS.DELETE_SHARING, payload: { deleteSharing } });
        },
    });

    const [deleteTagging, { loading: loadingDeleteTagging }] = useMutation(DELETE_TAGGING, {
        onCompleted: ({ deleteTagging }) => {
            dispatch({ type: TAGGINGS.DELETE_TAGGING, payload: { deleteTagging } });
        },
    });

    const download = async (tagging) => {
        const { file, id, name } = tagging;
        const { url } = file;

        let response = await fetch(url);
        let reader = response.body.getReader();
        let total = +response.headers.get('Content-Length');
        let loaded = 0;
        let chunks = [];
        while (true) {
            let { done, value } = await reader.read();
            if (done) {
                break;
            }
            chunks.push(value);
            loaded += value.length;
            const percentage = parseInt((loaded * 100) / total, 10);
            message.open({
                content: (
                    <>
                        <Progress
                            percent={percentage}
                            status={percentage < 100 ? 'active' : 'success'}
                        />
                        <span className="mr-5">{name}</span>
                    </>
                ),
                duration: 0,
                key: `TaggingMessage${id}`,
            });
            if (percentage === 100) {
                setTimeout(() => message.destroy(`TaggingMessage${id}`), 2000);
            }
        }

        return { ...file, blob: new Blob(chunks) };
    };

    const downloadTaggings = async (taggings, options = {}) => {
        const files = await Promise.all(taggings.map((tagging) => download(tagging)));
        return files;
    };

    const [getTaggingsFromTag, { loading: loadingGetTaggingsFromTag }] = useMutation(CREATE_TAG, {
        onCompleted: ({ createTag: { taggings = { edges: [] } } }) => {
            dispatch({
                type: TAGGINGS.LIST_TAGGINGS,
                payload: { taggings },
            });
        },
    });

    let [listTaggings, { loading, fetchMore }] = useLazyQuery(LIST_TAGGINGS, {
        variables: { id: tagId, first: 10 },
        onCompleted: (data = {}) => {
            const { tag = {} } = data;
            const { taggings = { edges: [] } } = tag;
            dispatch({
                type: TAGGINGS.LIST_TAGGINGS,
                payload: { taggings },
            });
        },
    });

    const moveTaggings = useCallback(
        async (taggings, { tag }) => {
            const { accountId, name } = tag;
            taggings = taggings.filter(({ node }) => node.tag.id !== tag.id);
            if (!taggings.length) {
                return;
            }
            const {
                data: { createTag: { taggings: tagTaggings = { edges: [] } } = {} } = {},
            } = await getTaggingsFromTag({ variables: { accountId, name } });
            const names = tagTaggings.edges
                .filter(
                    ({
                        node: {
                            tag: { id },
                        },
                    }) => id === tag.id
                )
                .map(({ node: { name } }) => name);

            taggings.forEach(async ({ node: { file, id, name } }) => {
                await createTagging({
                    variables: {
                        fileId: file.id,
                        name: getUniqueName(name, { names }),
                        tagId: tag.id,
                    },
                });
                deleteTagging({ variables: { id } });
            });
        },
        [taggings.edges]
    );

    const pasteTaggings = useCallback(
        (taggings, { tag }) => {
            const names = state.taggings.edges
                .filter(
                    ({
                        node: {
                            tag: { id },
                        },
                    }) => id === tag.id
                )
                .map(({ node: { name } }) => name);
            const copiedTaggings = taggings.filter(({ copied }) => copied);
            const cutedTaggings = taggings.filter(({ cuted }) => cuted);

            copiedTaggings.length &&
                copiedTaggings.forEach(({ node: { file, id, name } }) => {
                    createTagging({
                        variables: { file, name: getUniqueName(name, { names }), tag },
                    });
                });

            cutedTaggings.length && moveTaggings(cutedTaggings, { tag });
        },
        [taggings.edges]
    );

    const [replaceTaggingFile] = useMutation(REPLACE_TAGGING_FILE, {
        onCompleted: ({ replaceTaggingFile }) => {
            dispatch({
                type: TAGGINGS.REPLACE_TAGGING_FILE,
                payload: { replaceTaggingFile },
            });
        },
    });

    const selectTagging = (tagging) => {
        dispatch({ type: TAGGINGS.SELECT, payload: { tagging } });
    };

    let [
        showTagging,
        { data: { tagging: taggingToShow } = {}, loading: loadingShowTagging },
    ] = useLazyQuery(SHOW_TAGGING, {
        variables: { id: tagId, first: 10 },
        onCompleted: ({ tagging }) => {
            tagging &&
                dispatch({
                    type: TAGGINGS.SHOW_TAGGING,
                    payload: { tagging },
                });
        },
    });

    let [showTaggingWithUrl, { loading: loadingShowTaggingWithUrl }] = useLazyQuery(
        SHOW_TAGGING_WITH_URL,
        {
            variables: { id: tagId, first: 10 },
            onCompleted: ({ tagging }) => {
                tagging &&
                    dispatch({
                        type: TAGGINGS.SHOW_TAGGING_WITH_URL,
                        payload: { tagging },
                    });
            },
        }
    );

    const uncheckAllTaggings = () => {
        dispatch({ type: TAGGINGS.UNCHECK_ALL });
    };

    const uncheckTaggings = (taggings) => {
        dispatch({ type: TAGGINGS.UNCHECK, payload: { taggings } });
    };

    const [updateTagging, { loading: loadingUpdateTagging }] = useMutation(UPDATE_TAGGING, {
        onCompleted: ({ updateTagging }) => {
            dispatch({ type: TAGGINGS.UPDATE_TAGGING, payload: { updateTagging } });
        },
    });

    const listMoreTaggings = useCallback(
        () =>
            fetchMore &&
            fetchMore({
                variables: { id: tagId, cursor },
                updateQuery: (prev = {}, { fetchMoreResult = {} } = {}) => {
                    let { tag = {} } = prev;
                    let { taggings: tagTaggings = {} } = tag;
                    let { edges: tagTaggingsEdges = [] } = tagTaggings;
                    let { taggings = { edges: [] } } = fetchMoreResult.tag || {};

                    dispatch({
                        type: TAGGINGS.LIST_TAGGINGS,
                        payload: { taggings },
                    });
                    return taggings.edges.length
                        ? {
                              tag: {
                                  ...tag,
                                  taggings: {
                                      ...tagTaggings,
                                      ...taggings,
                                      edges: [...tagTaggingsEdges, ...taggings.edges],
                                  },
                              },
                          }
                        : prev;
                },
            }),
        [cursor, tagId]
    );

    loading =
        loading ||
        loadingCreateSharing ||
        loadingCreateSharing ||
        loadingCreateTagging ||
        loadingDeleteSharing ||
        loadingDeleteTagging ||
        loadingGetTaggingsFromTag ||
        loadingShowTagging ||
        loadingShowTaggingWithUrl ||
        loadingUpdateTagging;

    if (process.env.NODE_ENV === 'development') {
        console.log('TAGGINGS >>>', state);
    }

    return (
        <TaggingsContext.Provider
            value={{
                ...state,
                checkAllTaggings,
                checkTaggings,
                clearTaggings,
                copyTaggings,
                createFile,
                createSharing,
                createTagging,
                cursor,
                cutTaggings,
                deleteSharing,
                deleteTagging,
                downloadTaggings,
                getTaggingsFromTag,
                hasNextPage,
                listTaggings: fetchMore ? listMoreTaggings : listTaggings,
                loading,
                moveTaggings,
                pasteTaggings,
                replaceTaggingFile,
                selectTagging,
                showTagging,
                showTaggingWithUrl,
                taggingToShow,
                uncheckAllTaggings,
                uncheckTaggings,
                updateTagging,
            }}
        >
            {children}
        </TaggingsContext.Provider>
    );
};

export const TaggingsConsumer = TaggingsContext.Consumer;
export default TaggingsContext;
