// @flow
import { PropTypes } from 'prop-types';
import { Component } from 'react';
import ReactGA from 'react-ga';
import { debug, SECRETS } from '../_config';

type PropsT = {
    users: UsersT,
};

class GoogleAnalytics extends Component<PropsT> {
    static contextTypes = {
        router: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const { users } = this.props;
        const { all: allUsers } = users;
        const user = allUsers.find((user) => user.current) || {};
        const { id: userId } = user;

        this.sendPageView = this.sendPageView.bind(this);

        ReactGA.initialize(SECRETS.analytics, {
            debug,
            titleCase: false,
            gaOptions: { userId },
        });
    }

    componentDidMount() {
        this.sendPageView(this.context.router.history.location);
        this.context.router.history.listen(this.sendPageView);
    }

    sendPageView(location) {
        const { users } = this.props;
        const { all: allUsers } = users;
        const user = allUsers.find((user) => user.current) || {};
        const { id: userId } = user;

        ReactGA.set({ page: location.pathname, userId });
        ReactGA.pageview(location.pathname);
    }

    render() {
        return this.props.children;
    }
}

export { GoogleAnalytics };
export default GoogleAnalytics;
