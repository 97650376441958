export const TAGGINGS = {
    INITIAL_STATE: {
        checkedTaggings: [],
        current: undefined,
        taggings: { edges: [] },
    },

    CHECK: 'TAGGINGS_CHECK',
    CHECK_ALL: 'TAGGINGS_CHECK_ALL',
    CLEAR: 'TAGGINGS_CLEAR',
    COPY: 'TAGGINGS_COPY',
    CUT: 'TAGGINGS_CUT',
    CREATE_FILE: 'TAGGINGS_CREATE_FILE',
    CREATE_SHARING: 'TAGGINGS_CREATE_SHARING',
    CREATE_TAGGING: 'TAGGINGS_CREATE_TAGGING',
    DELETE_SHARING: 'TAGGINGS_DELETE_SHARING',
    DELETE_TAGGING: 'TAGGINGS_DELETE_TAGGING',
    DOWNLOAD: 'TAGGINGS_DOWNLOAD',
    LIST_TAGGINGS: 'TAGGINGS_LIST_TAGGINGS',
    LIST_SHARINGS: 'TAGGINGS_LIST_SHARINGS',
    MOVE: 'TAGGINGS_MOVE',
    REPLACE_TAGGING_FILE: 'TAGGINGS_REPLACE_TAGGING_FILE',
    SELECT: 'TAGGINGS_SELECT',
    SHOW_TAGGING: 'TAGGINGS_SHOW_TAGGING',
    SHOW_TAGGING_WITH_URL: 'TAGGINGS_SHOW_TAGGING_WITH_URL',
    UNCHECK: 'TAGGINGS_UNCHECK',
    UNCHECK_ALL: 'TAGGINGS_UNCHECK_ALL',
    UPDATE_TAGGING: 'TAGGINGS_UPDATE_TAGGING',
};

export const TaggingsReducer = (draft, action) => {
    const { payload = {}, type } = action;
    switch (type) {
        case TAGGINGS.CHECK: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    payload.taggings.find(({ node }) => node.id === tagging.node.id)
                        ? { ...tagging, checked: true }
                        : tagging
                ),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.CHECK_ALL: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    payload.tag.id === tagging.node.tag.id ? { ...tagging, checked: true } : tagging
                ),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.DOWNLOAD: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    tagging.file.id === payload.file.id
                        ? {
                              ...tagging,
                              progress: payload.progress,
                          }
                        : tagging
                ),
            };
            break;
        }

        case TAGGINGS.LIST_TAGGINGS: {
            draft.taggings = {
                ...draft.taggings,
                ...payload.taggings,
                edges: draft.taggings.edges.concat(
                    payload.taggings.edges.filter(
                        ({ node: a }) => !draft.taggings.edges.some(({ node: b }) => a.id === b.id)
                    )
                ),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            draft.pageInfo = payload.taggings.pageInfo;
            break;
        }

        case TAGGINGS.CREATE_FILE:
        case TAGGINGS.CREATE_TAGGING: {
            draft.taggings = {
                ...draft.taggings,
                edges: [
                    ...(!draft.taggings.edges.filter(
                        ({ node: tagging }) => tagging.id === payload.tagging.id
                    ).length
                        ? [{ node: { ...payload.tagging, current: !!payload.current } }]
                        : []),
                    ...draft.taggings.edges,
                ].sort(({ node: a }, { node: b }) => (a.name < b.name ? -1 : 1)),
            };
            payload.current &&
                (draft.current = draft.taggings.edges.find(({ current }) => current));
            break;
        }

        case TAGGINGS.CREATE_SHARING: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    tagging.node.id === payload.createSharing.tagging.id
                        ? {
                              ...tagging,
                              node: {
                                  ...tagging.node,
                                  sharings: { edges: [{ node: payload.createSharing }] },
                              },
                          }
                        : tagging
                ),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.DELETE_SHARING: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    tagging.node.id === payload.deleteSharing.tagging.id
                        ? {
                              ...tagging,
                              node: {
                                  ...tagging.node,
                                  sharings: {
                                      ...tagging.node.sharings,
                                      edges: tagging.node.sharings.edges.filter(
                                          ({ node }) => node.id !== payload.deleteSharing.id
                                      ),
                                  },
                              },
                          }
                        : tagging
                ),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.DELETE_TAGGING: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.filter(
                    ({ node: { id } }) => id !== payload.deleteTagging.id
                ),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.CLEAR: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) => ({
                    ...tagging,
                    checked: false,
                    current: false,
                })),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            draft.pageInfo = {};
            break;
        }

        case TAGGINGS.COPY: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) => ({
                    ...tagging,
                    checked: false,
                    copied: payload.taggings.some(({ id }) => id === tagging.node.id),
                    cuted: false,
                })),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.CUT: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) => ({
                    ...tagging,
                    checked: false,
                    copied: false,
                    cuted: payload.taggings.some(({ id }) => id === tagging.node.id),
                })),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.MOVE_SUCCESS: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) => ({
                    ...tagging,
                    checked: false,
                    copied: false,
                    cuted: false,
                })),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.SELECT: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges
                    .map((tagging) =>
                        payload.tagging.id === tagging.node.id
                            ? {
                                  ...tagging,
                                  node: { ...tagging.node, ...payload.tagging },
                                  current: true,
                              }
                            : tagging
                    )
                    .concat(
                        [{ node: payload.tagging, current: true }].filter(
                            ({ node: a }) =>
                                !draft.taggings.edges.some(({ node: b }) => a.id === b.id)
                        )
                    ),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            break;
        }

        case TAGGINGS.SHOW_TAGGING: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges
                    .map((tagging) =>
                        payload.tagging.id === tagging.node.id
                            ? {
                                  ...tagging,
                                  current: true,
                                  node: { ...tagging.node, ...payload.tagging },
                              }
                            : tagging
                    )
                    .concat(
                        [{ node: payload.tagging, current: true }].filter(
                            ({ node: a }) =>
                                !draft.taggings.edges.some(({ node: b }) => a.id === b.id)
                        )
                    ),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            break;
        }

        case TAGGINGS.REPLACE_TAGGING_FILE: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    tagging.node.id === payload.replaceTaggingFile.id
                        ? {
                              ...tagging,
                              node: {
                                  ...tagging.node,
                                  file: {
                                      ...tagging.node.file,
                                      url: payload.replaceTaggingFile.file.url,
                                  },
                              },
                          }
                        : tagging
                ),
            };
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            break;
        }

        case TAGGINGS.UNCHECK: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    payload.taggings.find(({ node }) => node.id === tagging.node.id)
                        ? { ...tagging, checked: false }
                        : tagging
                ),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.UNCHECK_ALL: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) => ({ ...tagging, checked: false })),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            break;
        }

        case TAGGINGS.UPDATE_TAGGING: {
            draft.taggings = {
                ...draft.taggings,
                edges: draft.taggings.edges.map((tagging) =>
                    payload.updateTagging.id === tagging.node.id
                        ? { ...tagging, node: { ...tagging.node, ...payload.updateTagging } }
                        : tagging
                ),
            };
            draft.checkedTaggings = draft.taggings.edges
                .filter(({ checked }) => checked)
                .map(({ node }) => node);
            draft.current = (draft.taggings.edges.find(({ current }) => current) || {}).node;
            break;
        }

        default: {
            break;
        }
    }
};
